@import "../index.scss";
@import "./playerquestions.scss";

.leader-board-table-row {
    transform: translateX(0) !important;
}

.custom-bg-forplayer-table .leader-board-section {
    padding: 0 21%;
    color: $white;
    &.custom-padding-for-ltable {
        // padding-right: 17%;
    }
    .leader-board-table-header {
        font-size: 14px;
        .drush-head{
            display: none !important;
        }
    }
    .leader-board-table-row {
        border-radius: 30px;
        padding: 2px 0;
        font-size: 14px;
        overflow: hidden;
        .correct-answer {
            width: 100px;
        }
        .fast-correct-answer {
            width: 150px;
        }
        .points {
            width: 90px;
        }
        .round-points {
            width: 95px;
        }
        .round-table {
            min-width: 40px;
        }
        .total-table {
            min-width: 30px;
        }
        .xtra-border {
            border-bottom: 1px solid;
            border-top: 1px solid;
        }
        .tr-count {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        &.blue {
            background: linear-gradient(90deg, rgba(98, 52, 197, 0.5) 0.65%, #6234C5 3.9%, rgba(98, 52, 197, 0.25) 50.64%);
            .xtra-border {
                border-color: #6234C5;
            }
            .tr-count {
                background-color: #6234C5;
            }
        }
        &.green {
            background: linear-gradient(90deg, rgba(133, 197, 52, 0.5) 0.65%, #85C534 3.9%, rgba(133, 197, 52, 0.25) 50.64%);
            .xtra-border {
                border-color: #85C534;
            }
            .tr-count {
                background-color: #85C534;
            }
        }
        &.lightblue {
            background: linear-gradient(90deg, rgba(52, 110, 197, 0.5) 0.65%, #346EC5 3.9%, rgba(52, 110, 197, 0.25) 50.64%);
            .xtra-border {
                border-color: #346EC5;
            }
            .tr-count {
                background-color: #346EC5;
            }
        }
        &.red {
            background: linear-gradient(90deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);
            .xtra-border {
                border-color: #C53434;
            }
            .tr-count {
                background-color: #C53434;
            }
        }
        &.cyan {
            background: linear-gradient(90deg, rgba(52, 197, 162, 0.5) 0.65%, #34C5A2 3.9%, rgba(52, 197, 162, 0.25) 50.64%);
            .xtra-border {
                border-color: #34C5A2;
            }
            .tr-count {
                background-color: #34C5A2;
            }
        }

    }
}

.leader-board-custom-align {
    .leader-board-section {
        .leader-board-table-row {
            background: linear-gradient(rgba(var(--element-color-rgb), 0.8) 0.65%, rgb(var(--element-color-rgb)) 3.9%, rgba(var(--element-color-rgb), 0.35) 60.64%);
            .round-table {
                min-width: 85px;
            }
            svg {
                @media screen and (max-width : $md-screen) {
                    margin-right: 0 !important;
                }
            }
            
            .total-table {
                min-width: 30px;
                font-family: "Comfortaa", cursive;
            }
            .xtra-border {
                border-bottom: 1px solid;
                border-top: 1px solid;
                border-color: rgb(var(--element-color-rgb));
            }
            .tr-count {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: rgb(var(--element-color-rgb));
            }
        }
    }
}

.list-item{
    .player-name{
        padding-top: 3px;
    }
}

.player-table-column{
    &.tbl-head{
        p {
            flex-grow: 1;
            min-width: 23% !important;
            @media(max-width:$xl-screen){
                min-width: 22.4% !important;
            }
            @media(max-width:$md-screen){
                min-width: 22.5% !important;
            }
            @media(max-width:$sm-screen){
                min-width: 21% !important;
            }
            @media(max-width:$xs-screen){
                min-width: 21% !important;
            }
            @media(max-width:420px){
                font-size: 10px;
                min-width: 18% !important;
            }
        }
    }
    span {
        flex-grow: 1;
        min-width: 26% !important;
        @media(max-width:$xl-screen){
            min-width: 26% !important;
        }
        @media(max-width:$lg-screen){
            min-width: 25.5% !important;
        }
        @media(max-width:$md-screen){
            min-width: 25.5% !important;
        }
        @media(max-width:$sm-screen){
            min-width: 25.6% !important;
        }
        @media(max-width:$xs-screen){
            min-width: 26% !important;
        }
    }
    p{
        text-align: center !important;
    }
}

.group-name-leader{
    max-width: 115px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

ul.leaderboard-ul-list {
    .leader-board-table-row{
        max-width: 100% !important;
    }
    .player-name-long {
        text-align: left !important;
    }
}

.footer-bg{
    position: fixed;
    @media screen and  (max-width:$sm-screen) {
        width: 100%;
    }
}