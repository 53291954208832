@import "../index.scss";

.presentation-qr-code {
    .qr-code-section {
        width: 25%;
        background: #0E1231;
        border: 1px solid #00FECC;
        box-shadow: 4px 4px 0px #18C27F;
        border-radius: 24px;
        overflow: hidden;
        .qr-code-head {
            font-size: 28px;
            padding: 20px;
        }
        .qr-code {
            background-color: $white;
        }
    }
}