@import "../index.scss";

.presentation-question {
  color: $white;
  .main-content {
    padding-top: 20px;
    min-height: calc(100vh - 20px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pq-whole-section {
      width: 100%;
      padding: 7% 10%;
      font-family: $sub-font;
      .pq-head {
        font-size: 30px;
      }
      .time-left-loader {
        font-family: $quest-font;
        .time {
          font-size: 32px;
        }
      }
      .quest-img {
        width: 40%;
        box-shadow: 2px 2px 0px #18c27f;
        border: 1px solid #d8ccc1;
        border-radius: 18px;
        overflow: hidden;
        height: 280px;
        background-color: #fff;
        &.no-img{
          background-color: transparent !important;
          border: none !important;
          box-shadow: none !important;
        }
        img{
          width: 100%;
          height: 100% !important;
          object-fit: contain;
        }
      }
      .answered-section {
        font-family: $quest-font;
        .answered-data {
          font-size: 32px;
          .line-seperate {
            height: 5px;
            background-color: $white;
            width: 60px;
          }
        }
      }
      .progress-circle {
        $rotationSteps: 100;
        $rotationIncrement: (360 / $rotationSteps) / 2;
    
        --diameter: 150px;
        --stroke: 10px;
        border-radius: 50%;
        height: var(--diameter);
        width: var(--diameter);
        position: relative;
    
        &__mask,
        &__fill {
          border-radius: 50%;
          clip: rect(
            0,
            var(--diameter),
            var(--diameter),
            calc(var(--diameter) / 2)
          );
          height: var(--diameter);
          width: var(--diameter);
          overflow: hidden;
          position: absolute;
        }
        &__fill {
          border: 5px solid;
          clip: rect(0, calc(var(--diameter) / 2), var(--diameter), 0);
        }
        &:after {
          position: absolute;
          content: "";
          border-radius: 50%;
          display: flex;
          bottom: var(--stroke);
          top: var(--stroke);
          left: var(--stroke);
          right: var(--stroke);
          justify-content: center;
          align-items: center;
          color: $white;
          font-size: 1.5rem;
        }
        @for $step from 1 through $rotationSteps {
          &[data-progress="#{$step}"] {
            .progress-circle__mask--full,
            .progress-circle__fill {
              transform: rotate(($step * $rotationIncrement) + deg);
            }
          }
        }
      }
      .time-data,
      .answered-data {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .answer-option-section {
          font-family: $quest-font;
          font-size: 14px;
          .answer-option {
            box-shadow: 2px 2px 0px #18C27F, inset 0px 0px 0px 1px #D8CCC1;
            border-radius: 12px;
            padding-right: 10px;
            overflow: hidden;
            .option-count {
                font-family: $sub-font;
                font-size: 40px;
            }
            .option-detail {
                padding: 12px;
                background-color: rgba($color: $white, $alpha: 0.7);
                color: $text-dark;
                min-height: 45px;
                height: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

            }
            &.first {
                background-color: #4FBE3D;
            }
            &.second {
                background-color: #3D80BE;
            }
            &.third {
                background-color: #E95D6E;
            }
            &.fourth {
                background-color: #A8F55B;
            }
          }
      }
      .graph-section{
        min-height: 500px;
        @media screen and (max-height:800px) {
          min-height: 430px;
        }
      }
    }
  }
  @media(max-height:800px){
    min-height: 110vh;
  }
  &.dh-full{
    min-height: 100%;
    .main-content {
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .pq-whole-section{
        padding: 0% 10%;
      }
    }
  }
}
