@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: "Qualy";
    src: url("./assets/Fonts/Qualy.otf") format("opentype"),
        url("./assets/Fonts/Qualy.woff") format("woff"),
        url("./assets/Fonts/Qualy.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$primary-dark: #088251;
$primary-color: #18c27f;
$primary-light: #00fecc;
$btn-color: #aa6600;
$sidebar-color: #0e1231;
$diasble-color: #90877e;
$hover-color: #d07d00;
$btn-gradient: #7f18c2;
$text-dark: #211d19;
$shadow-color: #1fc57e;
$alert-primary: #00fecc;
$alert-secondary: #088251;
$alert-success: #008800;
$alert-danger: #ee0000;
$alert-warning: #cc0069;
$alert-dark: #0e1231;
$white: #ffffff;
$black: #000000;
$red: #c51f1f;
$blue: #4a1fc5;
$pink-light: #976e78;
$green: #116119;
$blue-light: #1c7cd4;
$green-light: #bcd0a2;
$palette-yellow: #c5ad1f;
$palette-green: #4ec51f;
$palette-light-green: #1fc57e;
$palette-light-blue: #1f7ec5;
$palette-blue: #4e1fc5;
$palette-pink: #c51fad;
$error-line-danger: #F56666;
$error-line-green: #66B866;
$form-border-color: #d8ccc1;
$choose-red: #c53434;
$choose-yellow: #c59c34;
$choose-green: #85c534;
$choose-light-green: #34c5a2;
$choose-blue: #346ec5;
$choose-violet: #c534a5;
$choose-pink: #c5344e;
$sb-active: #4cad00;
$round-bg: #055636;
// Fonts
$head-font: "Qualy";
$main-font: "DM+Sans", sans-serif;
$sub-font: 'Fredoka One', cursive;
$quest-font: 'Comfortaa', cursive;
$formfield-radius: 10px;
$formfield-height: 48px;
$font-normal: 400;
$font-medium: 500;
$font-semi: 600;
$font-bold: 700;
// Responsive Screens
$xl-screen: 1399.98px;
$lg-screen: 1199.98px;
$md-screen: 991.98px;
$sm-screen: 767.98px;
$xs-screen: 575.98px;
@mixin transition($time) {
    transition: $time ease all;
}

@mixin BoxShadow($dp) {
    @if $dp==0 {
        box-shadow: none;
    } @else if $dp==1 {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    } @else if $dp==2 {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    } @else if $dp==3 {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
            0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    } @else if $dp==4 {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    } @else if $dp==6 {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    } @else if $dp==8 {
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 5px 5px -3px rgba(0, 0, 0, 0.2);
    } @else if $dp==9 {
        box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14),
            0 3px 16px 2px rgba(0, 0, 0, 0.12),
            0 5px 6px -3px rgba(0, 0, 0, 0.2);
    } @else if $dp==12 {
        box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
            0 5px 22px 4px rgba(0, 0, 0, 0.12),
            0 7px 8px -4px rgba(0, 0, 0, 0.2);
    } @else if $dp==16 {
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
            0 6px 30px 5px rgba(0, 0, 0, 0.12),
            0 8px 10px -5px rgba(0, 0, 0, 0.2);
    } @else if $dp==24 {
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
            0 9px 46px 8px rgba(0, 0, 0, 0.12),
            0 11px 15px -7px rgba(0, 0, 0, 0.2);
    }
}

@mixin borderRadius($br) {
    @if $br==0 {
        border-radius: 0;
    } @else if $br==1 {
        border-radius: 1px;
    } @else if $br==2 {
        border-radius: 2px;
    } @else if $br==3 {
        border-radius: 3px;
    } @else if $br==4 {
        border-radius: 4px;
    } @else if $br==5 {
        border-radius: 5px;
    } @else if $br==10 {
        border-radius: 10px;
    } @else if $br==15 {
        border-radius: 15px;
    } @else if $br==20 {
        border-radius: 20px;
    } @else if $br==25 {
        border-radius: 25px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $sub-font;
}

.main-bg {
    background-image: url('./components/assets/images/background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    // z-index: -5;
    // position: relative;
    &.custom-bg-1 {
        background-image: url('./components/assets/images/background-2.jpg');
    }
    &.custom-bg-2 {
        background-image: url('./components/assets/images/background-3.jpg');
    }
    &.custom-bg-3 {
        background-image: url('./components/assets/images/background-4.jpg');
    }
    &.no-team-shade, &.team-shade{
        &::before {
            background: linear-gradient(225deg, rgba(170, 102, 0, 0.8) 0%, rgba(127, 24, 194, 0.8) 100%);
            content: "";
            width: 100%;
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &.team-shade{
        &::before {
            background: linear-gradient(225deg, rgba(52, 110, 197, 0.8) 0%, rgba(52, 110, 197, 0.392) 100%);
        }
    }
}

// body {
//     background-image: url('./components/assets/images/background.jpg');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     min-height: 100vh;
// }

.custom-dropdown-text-content {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 1100px) {
        max-width: 100px;
    }
    @media screen and (max-width: 1100px) and (min-width: $md-screen) {
        max-width: 80px;
    }
}

.green-border {
    border-bottom: 7px solid #66B866;
    padding-bottom: .5rem;
}

.modal {
    background: linear-gradient(
        225deg,
        rgba($btn-color, 0.8) 0%,
        rgba($btn-gradient, 0.8) 100%
    ) !important;
    z-index: 99999;
    .modal-header {
        border-bottom: none;
        .btn-close {
            color: $btn-color;
            background: none;
            font-size: 24px;
            line-height: 24px;
            opacity: 1;
        }
        h5, h3 {
            color: $primary-dark;
            font-family: $sub-font;
        }
    }
    
    p {
        font-size: 14px;
        &.custom-opacity {
            color: $diasble-color;
        }
    }
    .modal-footer {
        justify-content: space-between;
        border-top: none;
    }
    .modal-content {
        box-shadow: 4px 4px 0 $shadow-color;
        border-radius: 24px;
    }
    .v-btn {
        right: 5px;
        top: 2px;
        color: $btn-color;
    }
}

.custom-form-field-with-validation {
    .validation-line {
        height: 8px;
        right: 15px;
        left: 15px;
        bottom: 3px;
        &.error {
            background-color: $error-line-danger;
        }
        &.success {
            background-color: $error-line-green;
        }
    }
    .validation-icon {
        bottom: 11px;
        right: 15px;
        &.error {
            color: $error-line-danger;
        }
        &.success {
            color: $error-line-green;
        }
    }
}

.custom-light-navy-bg {
    background-color: rgba($color: $sidebar-color, $alpha: 0.7);
    padding: 15px;
    border-radius: 18px;
    * {
        font-family: Fredoka One;
    }
}

.yakeety-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height: 100vh;
    min-width: 100%;
    background: linear-gradient(
        225deg,
        rgba($btn-color, 1) 0%,
        rgba($btn-gradient, 1) 100%);
    z-index: 9999999;
    // z-index: 2 !important;
    img {
        width: 100px;
        height: auto;
    }
}

.team-info-footer {
    position: fixed;
    bottom: 25px;
    right: 20px;
    z-index: 10;
    .team-icon-sec {
        border-radius: 8px;
    }
    @media screen and (max-width:$xs-screen) {
        top: 20px;
        z-index: 999;
        bottom: initial;
    }
}

.yakeety-intro-video {
    height: 75vh;
    width: 100vw;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $md-screen) {
        height: 65vh;
    }
    @media screen and (max-width: $sm-screen) {
        height: 100%;
    }
    video {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 9;
    }
} 


button {
    &.btn {
        &.btn-primary {
            &.dynamic-btn {
                &:hover {
                    background-image: linear-gradient(rgb(var(--element-color)), rgb(var(--element-color))) !important;
                }
            }
        }
    }
}

.img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.view-team-members-modal {
    &.modal {
        .modal-content {
            padding: 1rem 2rem;
            .team-info-footer {
                &.custom-for-modal-head {
                    position: unset;
                    h5 {
                        text-transform: capitalize;
                    }
                }
            }
        }
        .modal-header {
            .btn-close {
                position: absolute;
                right: 2.8rem;
            }
        } 
    } 
    
}

.player-intro-video {
    .header-bg {
        top: 0;
    }
}

.custom-margin-for-presentation-lb {
    .leader-board-section {
        margin-left: 20%;
        z-index: 99;
    }
}

.game-paused-text-section {
    .custom-font-color-white {
        color: $white;
    }
}

.alert{
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 14px;
    border-radius: 12px;
    padding: 8px 15px;
    &.alert-warning{
        background-color: $alert-warning;
        border: 1px solid $alert-warning;
        color: #fff;
    }
}

.player-questions {
    &.player-answers {
        .intro-text-area {
            min-height: 85vh;
            height: 85vh;
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: $xs-screen) {
                padding-top: 0;
            }
            .main-content {
                min-height: 100%;
                padding-top: 0;
            }
        }
    }
}
