.full-section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    h1 {
        font-size: 150px;
        font-weight: 700;
        color: #06efb9;
        margin: 0;
        line-height: .9;
        animation: topBottom 5s;
    }
    h1:last-child{
        animation: topBottom 8s;
    }
    p {
        font-size: 29px;
        color: #8cdbbc;
        text-transform: uppercase;
        border-top: 2px solid;
        margin-top: 5px;
    }
    img {
        height: 120px;
        margin: 0 10px;
    }
    .error-design {
        overflow: hidden;
    }
}

@keyframes topBottom{
    0% {transform: translateY(150px);}
    80% {transform: translateY(-10px);}
    100% {transform: translateY(0px);}
}