@import '../index.scss';

.presentation-controller-main {
    min-height: calc(100vh - 24px);
    .main-content {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        h2 {
            color: $white;
        }
    }
    @media screen and (max-width: $sm-screen) {
        .navbar-section,
        .footer-section,
        .main-content,
        .main-content img {
            display: none;
        }
    }
}
