@import "../index.scss";

.mc-for-presentation-round-view {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}
.sat-orbit-hide {
    .sat-orbit-hide-custom-width {
        // width: 95vw;
        width: 1151px;
        // top: 57px;
        @media screen and (max-width: $lg-screen) {
            width: 750px;
        }
    }
    .sat-orbit-line {
        height: 190px;
        overflow: hidden;
        position: relative;
        margin-bottom: 50px;
        .sat-orbit {
            // width: 95vw;
            // height: 95vw;
            width: 1151px;
            height: 1151px;
            background-color: transparent;
            border: 2px solid $white;
            border-radius: 50%;
            position: relative;
            z-index: 11;
            margin-top: 3px;
            @media screen and (max-width: $lg-screen) {
                width: 750px;
                height: 750px;
            }
        }
        .soh-mask {
            position: absolute;
            top: 0;
            // width: 95vw;
            // height: 95vw;
            width: 1151px;
            height: 1151px;
            @media screen and (max-width: $lg-screen) {
                width: 750px;
                height: 750px;
            }
        }
        .sat-orbit-highlight {
            position: absolute;
            // width: 100%;
            // height: 600px;
            background-color: transparent;
            border: 8px solid transparent;
            border-radius: 50%;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            &.first {
                border-left-color: #85C534;
                z-index: 10;
                transform: rotate(23deg);
                animation: orbitanimation1 4s;
                &.closed {
                    border-left-color: transparent;
                }
            }
            &.second {
                border-left-color: #6234C5;
                z-index: 9;
                transform: rotate(45deg);
                animation: orbitanimation2 3s;
                &.closed {
                    border-left-color: transparent;
                }
                // @media screen and (max-width: 992px) {
                //     transform: rotate(54deg);
                // }
            }
            &.third {
                border-left-color: #346EC5;
                z-index: 8;
                transform: rotate(62deg);
                animation: orbitanimation3 3s;
                &.closed {
                    border-left-color: transparent;
                }
                // @media screen and (max-width: 992px) {
                //     transform: rotate(72deg);
                // }
            }
            &.fourth {
                border-left-color: #34C5A2;
                z-index: 7;
                transform: rotate(76deg);
                animation: orbitanimation4 4s;
                &.closed {
                    border-left-color: transparent;
                }
                // @media screen and (max-width: 1148px) and (min-width: 992px) {
                //     transform: rotate(80deg);
                // }
                // @media screen and (max-width: 992px) and (min-width: 900px) {
                //     transform: rotate(85deg);
                // }
                // @media screen and (max-width: 900px) {
                //     transform: rotate(88deg);
                // }
            }
            &.fifth {
                border-left-color: #6234C5;
                z-index: 6;
                transform: rotate(125deg);
                animation: orbitanimation5 5s;
                &.closed {
                    border-left-color: transparent;
                }
            }
        }
    }
    .sat-planet-0 {
        bottom: 15px;
        left: 97px;
        width: 120px;
        z-index: 12;
        @media screen and (max-width: $lg-screen) {
            bottom: 22px;
            left: 2px;
            width: 100px;
        }
    }
    .sat-planet-com {
        z-index: 12;
        border: 8px solid transparent;
        border-radius: 50%;
        &.sat-planet-halo {
            overflow: unset;
            .planet-with-action {
                transform: scale(2.3);
                animation: planetHalo 4s ease;
            }
        }
        .planet-with-action {
            overflow: hidden;
            .closed-planet {
                position: absolute;
                top: 0;
                left: 0;
                display: none;
            }
        }
        &.closed {
            border: none;
            .planet-with-action {
                .closed-planet {
                    display: block;
                }
            }
        }
        &.sat-planet-1 {
            width: 90px;
            top: 14px;
            left: 292px;
            border-color: #85C534;
            @media screen and (max-width: $lg-screen) {
                top: 10px;
                left: 152px;
            }
        }
        &.sat-planet-2 {
            width: 80px;
            top: -30px;
            left: 524px;
            border-color: #6234C5;
            @media screen and (max-width: $lg-screen) {
                top: -32px;
                left: 321px;
            }
        }
        &.sat-planet-3 {
            width: 68px;
            left: 720px;
            top: -2px;
            border-color: #346EC5;
            @media screen and (max-width: $lg-screen) {
                left: 469px;
                top: -6px;
            }
            .round-winner {
                width: 50px;
                height: 50px;
                background-color: red;
                right: -10px;
                bottom: -30px;
            }
        }
        &.sat-planet-4 {
            width: 58px;
            left: 845px;
            top: 55px;
            border-color: #34C5A2;
            @media screen and (max-width: $lg-screen) {
                left: 573px;
                top: 54px;
            }
            .round-winner {
                width: 50px;
                height: 50px;
                background-color: pink;
                right: 0px;
                bottom: -35px;
            }
        }
        &.sat-planet-5,
        &.sat-planet-halo {
            width: 68px;
            right: 130px;
            bottom: 10px;
            border-color: rgb(197, 156, 52);
            @media screen and (max-width: $lg-screen) {
                right: 26px;
                bottom: 21px;
            }
            .round-winner {
                width: 50px;
                height: 50px;
                background-color: green;
                right: 0px;
                bottom: -25px;
            }
        }
        .round-winner {
            width: 60px;
            height: 60px;
            background-color: #346ec5;
            right: -25px;
            bottom: -18px;
            z-index: 9;
        }
    }
}

.main-content{
    .game-view-section{
        opacity: 0;
        animation: 2s showdelay 1s forwards;
        position: relative;
        z-index: 9;
       
        @media screen and (max-height: 750px) {
            transform: scale(.85);
            transform-origin: top;
        }
        &.map-section{
            @media screen and (max-height: 750px) {
                transform: scale(.55);
                transform-origin: top;
            }
            & + .orbit-path-bg{
                .ob-p-position {
                    right: -227px;
                    bottom: -505px;
                    .op-bg{
                        &.first-op {
                            width: 740px;
                            height: 740px;
                        }
                        &.second-op {
                            width: 510px;
                            height: 510px;
                        }
                        &.third-op {
                            width: 330px;
                            height: 330px;
                        }
                        &.fourth-op {
                            width: 200px;
                            height: 200px;
                        }
                    }
                }
            }
        }
        
        @media screen and (max-width: 992px) {
            transform: scale(0.8);
        }
        .sat-orbit-line {
            .svg-condition{
                display: none;
            }
        }
    }
    &.planet-resize{
        // .game-view-section{
        //     transform: scale(.55);
        //     transform-origin: top;
        // }
        ~.footer-section{
            .footer-bg{
                .footer-overlay-img{
                    bottom: 10px;
                    .ho-logo-section-footer-logo{
                        width: 100px;
                    }
                }
            }
        }
    }
}

@keyframes showdelay {
    from {opacity: 0;}
    to {opacity: 1;}
}

.orbit-path-bg {
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    .ob-p-position {
        // right: -37%;
        // bottom: -90%;
        right: -417px;
        bottom: -515px;
        @media screen and (max-height: 750px) {
            right: -347px;
            bottom: -415px;
        }
        @media screen and (max-width: $lg-screen) and (max-height: 750px) {
            right: -277px;
            bottom: -375px;  
        }
    }
    .op-bg {
        border: 1px solid #3F3F3F;
        border-radius: 50%;
        background-color: transparent;
        &.first-op {
            // width: 110vw;
            // height: 110vw;
            width: 1350px;
            height: 1350px;
            @media screen and (max-width: $lg-screen) {
                width: 1230px;
                height: 1230px;
            }
            @media screen and (max-height: 750px) {
                width: 1140px;
                height: 1140px;
            }
            @media screen and (max-width: $lg-screen) and (max-height: 750px) {
                width: 890px;
                height: 890px;
            }
        }
        &.second-op {
            // width: 80vw;
            // height: 80vw;
            width: 950px;
            height: 950px;
            @media screen and (max-height: 750px) {
                width: 800px;
                height: 800px;
            }
            @media screen and (max-width: $lg-screen) and (max-height: 750px) {
                width: 650px;
                height: 650px;
            }
        }
        &.third-op {
            // width: 55vw;
            // height: 55vw;
            width: 600px;
            height: 600px;
            @media screen and (max-width: $lg-screen) {
                width: 680px;
                height: 680px;
            }
            @media screen and (max-height: 750px) {
                width: 500px;
                height: 500px;
            }
            @media screen and (max-width: $lg-screen) and (max-height: 750px) {
                width: 440px;
                height: 440px;
            }
        }
        &.fourth-op {
            // width: 30vw;
            // height: 30vw;
            width: 350px;
            height: 350px;
            @media screen and (max-width: $lg-screen) {
                width: 450px;
                height: 450px;
            }
            @media screen and (max-height: 750px) {
                width: 310px;
                height: 310px;
            }
            @media screen and (max-width: $lg-screen) and (max-height: 750px) {
                width: 260px;
                height: 260px;
            }
        }
    }
}

@keyframes orbitanimation1 {
    from {
        transform: rotate(-20deg);
    }
    to {
        transform: rotate(23deg);
    }
}

@keyframes orbitanimation2 {
    from {
        transform: rotate(-20deg);
    }
    to {
        transform: rotate(45deg);
    }
}

@keyframes orbitanimation3 {
    from {
        transform: rotate(-20deg);
    }
    to {
        transform: rotate(62deg);
    }
}

@keyframes orbitanimation4 {
    from {
        transform: rotate(-20deg);
    }
    to {
        transform: rotate(76deg);
    }
}

@keyframes orbitanimation5 {
    from {
        transform: rotate(-20deg);
    }
    to {
        transform: rotate(125deg);
    }
}

@keyframes planetHalo {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(2.3);
    }
}


.player-head-round {
    p {
        min-width: auto;
        flex: 1;
    }
}

.duckrush-round-leaderboard {
    bottom: -20px;
}