@import '../../index.scss';

.normal-footer {
    width: 320px;
    svg {
        opacity: 0.6;
        fill: #c51f1f;
        &.second-svg {
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media screen and (max-width : $sm-screen) {
        width: 260px;
    }
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        max-width: 130px;
    }
    @media screen and (max-width : $xs-screen) {
        width: 150px;
    }
}

.long-footer {
    max-width: 750px;
    width: 100%;
    // @media screen and (max-height: 750px) {
    //     max-width: 850px;
    // }
    svg {
        opacity: 0.6;
        fill: #c51f1f;
        &.second-svg {
            bottom: -6px;
            left: -60px;
            right: 10px;
        }
    }
    @media screen and (max-width : $sm-screen) {
        width: 320px;
    }
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        max-width: 350px;
    }
}

.long-header {
    width: 100vw;
    top: -20px;
    svg {
        opacity: 0.6;
        fill: #c51f1f;
        &.second-svg {
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .defualt-long-cloud{
        height: 150px;
        width: 100%;
        position: relative;
        .long-top {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            img{
               width: 100%; 
               height: auto;
               object-position: bottom;
            }
        }
    }
}

.normal-header {
    width: 320px;
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        width: 220px;
    }
    .defualt-right-cloud{
        display: none;
    }
    svg {
        opacity: 0.6;
        fill: #c51f1f;
        &.second-svg {
            top: 0;
            left: 0;
            right: 0;
        }
    }
    // @media screen and (max-width: $md-screen) and (min-width: $xs-screen) {
    //     width: 325px;
    // }
    @media screen and (max-width: $xs-screen) {
        width: 210px;
    }
}
