@import "../index.scss";
@import "./playerquestions.scss";


@for $i from 1 through 8 {
    .custom-podium-table .leader-board-table-row:nth-child(n + #{$i}) {
        margin-left: (calc(-15px * ($i - 1))) !important;
        @media screen and (max-width:$md-screen) {
            margin-left: 0 !important;
        }
    }
}

.custom-bg-forplayer-table .leader-board-section {
    background: rgba(14, 18, 49, 0.4);
    border-radius: 24px;
    padding: 20px;
    color: $white;
    &.custom-padding-for-ltable {
        // padding-right: 17%;
    }
    @media screen and (max-width: $sm-screen) {
        background: none;
        padding: 0;
    }
    .leader-board-table-header {
        font-size: 14px;
        .podium-table-head p{
            flex: 1;
        }
    }
    .leader-board-table-row {
        border-radius: 30px;
        padding: 2px 0;
        font-size: 14px;
        overflow: hidden;
        background: linear-gradient(rgba(var(--element-color-rgb), 0.8) 0.65%, rgb(var(--element-color-rgb)) 3.9%, rgba(var(--element-color-rgb), 0.35) 60.64%);
        .correct-answer {
            width: 100px;
        }
        .fast-correct-answer {
            width: 150px;
        }
        .points {
            width: 90px;
        }
        .round-points {
            width: 95px;
        }
        .round-table {
            min-width: 40px;
        }
        .total-table {
            min-width: 30px;
            font-family: $sub-font;
        }
        .xtra-border {
            border-bottom: 1px solid;
            border-top: 1px solid;
            border-color: rgb(var(--element-color-rgb));
        }
        .tr-count {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(var(--element-color-rgb));
        }
        &.blue {
            background: linear-gradient(90deg, rgba(98, 52, 197, 0.5) 0.65%, #6234C5 3.9%, rgba(98, 52, 197, 0.25) 50.64%);
            .xtra-border {
                border-color: #6234C5;
            }
            .tr-count {
                background-color: #6234C5;
            }
        }
        &.green {
            background: linear-gradient(90deg, rgba(133, 197, 52, 0.5) 0.65%, #85C534 3.9%, rgba(133, 197, 52, 0.25) 50.64%);
            .xtra-border {
                border-color: #85C534;
            }
            .tr-count {
                background-color: #85C534;
            }
        }
        &.lightblue {
            background: linear-gradient(90deg, rgba(52, 110, 197, 0.5) 0.65%, #346EC5 3.9%, rgba(52, 110, 197, 0.25) 50.64%);
            .xtra-border {
                border-color: #346EC5;
            }
            .tr-count {
                background-color: #346EC5;
            }
        }
        &.red {
            background: linear-gradient(90deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);
            .xtra-border {
                border-color: #C53434;
            }
            .tr-count {
                background-color: #C53434;
            }
        }
        &.cyan {
            background: linear-gradient(90deg, rgba(52, 197, 162, 0.5) 0.65%, #34C5A2 3.9%, rgba(52, 197, 162, 0.25) 50.64%);
            .xtra-border {
                border-color: #34C5A2;
            }
            .tr-count {
                background-color: #34C5A2;
            }
        }

        .team-icon svg {
            margin: 0 !important;
        }
    }
}

.group-name-leader{
    max-width: 110px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}


.leader-board-section {
    &.for-mob-screen {
        color: $white;
        // &.custom-padding-for-ltable {
        //     padding-right: 17%;
        // }
        .leader-board-table-header {
            font-size: 14px;
            width: 35px;
        }
        .leader-board-table-row {
            border-radius: 30px;
            padding: 2px 1px;
            font-size: 14px;
            overflow: hidden;
            max-width: 40px;
            width: 100%;
            .mob-xtra-border {
                border-right: 1px solid;
                border-left: 1px solid;
            }
            .tr-count {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            &.blue {
                background: linear-gradient(180deg, rgba(98, 52, 197, 0.5) 0%, #6234C5 6.05%, rgba(98, 52, 197, 0.25) 92.91%);
                .xtra-border {
                    border-color: #6234C5;
                }
                .tr-count {
                    background-color: #6234C5;
                }
            }
            &.green {
                background: linear-gradient(180deg, rgba(133, 197, 52, 0.5) 0%, #85C534 6.05%, rgba(133, 197, 52, 0.25) 92.91%);
                .xtra-border {
                    border-color: #85C534;
                }
                .tr-count {
                    background-color: #85C534;
                }
            }
            &.lightblue {
                background: linear-gradient(180deg, rgba(52, 110, 197, 0.5) 0.65%, #346EC5 3.9%, rgba(52, 110, 197, 0.25) 50.64%);
                .xtra-border {
                    border-color: #346EC5;
                }
                .tr-count {
                    background-color: #346EC5;
                }
            }
            &.red {
                background: linear-gradient(180deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);
                .xtra-border {
                    border-color: #C53434;
                }
                .tr-count {
                    background-color: #C53434;
                }
            }
            &.cyan {
                background: linear-gradient(180deg, rgba(52, 197, 162, 0.5) 0.65%, #34C5A2 3.9%, rgba(52, 197, 162, 0.25) 50.64%);
                .xtra-border {
                    border-color: #34C5A2;
                }
                .tr-count {
                    background-color: #34C5A2;
                }
            }
        }
    }
}

.podium-rating-section {
    background: linear-gradient(180deg, rgba(95, 51, 192, 0) 0%, #5F33C0 100%);
    width: 250px;
    border-radius: 24px;
    padding-bottom: 40px;
    margin: 0 auto;
    .star-rating {
        left: 10px;
        .small-star {
            width: 65px;
        }
    }
    .team-pos{
        display: none;
    }
}

.podium-msg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .msg-box{
        max-width: 680px;
        width: 100%;
        height: 480px;
        border-radius: 24px;
        text-align: center;
        position: relative;
        background: linear-gradient(225deg, #AA6600 0%, #7F18C2 100%);
        padding: 50px;
        color: #fff;
        margin: 20px;
        @media screen and (max-width:$sm-screen) {
            max-width: 650px;
            height: 440px;
            padding: 40px;
        }
        &::before{
            content: "";
            position: absolute;
            width: calc(100% - 50px);
            height: 100%;
            left: 25px;
            top: 10px;
            background: linear-gradient(180deg, rgba(14, 18, 49, 0) 0%, #0E1231 100%);
            border-radius: 30px;
            z-index: 9;
            @media screen and (max-width:$sm-screen) {
                width: calc(100% - 30px);
                left: 15px;
            }
        }
        h2{
            font-family: Fredoka One;
            font-size: 34px;
            font-weight: 400;
            line-height: 43px;
            text-align: center;
            position: relative;
            z-index: 10;
            @media screen and (max-width:$sm-screen) {
                font-size: 30px;
            }
            @media screen and (max-width:$xs-screen) {
                font-size: 25px;
            }
        }
        p{
            font-family: 'Comfortaa';
            font-weight: 700;
            font-size: 22px;
            margin-top: 20px;
            position: relative;
            z-index: 10;
            @media screen and (max-width:$sm-screen) {
                font-size: 20px;
            }
            @media screen and (max-width:$xs-screen) {
                font-size: 16px;
            }
        }
        img{
            width: 160px;
            height: auto;
            margin: 0 auto;
            margin-top: 0px;
            margin-top: 10px;
            position: relative;
            z-index: 10;
            margin-top: 20px;
            @media screen and (max-width:$sm-screen) {
                width: 120px;
            }
        }
    }
}

.footer-section.for-player-footer .footer-bg .footer-overlay-img {
    bottom: 0;
}

.lite-bg{
    &.astro-player {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}


.leader-board-custom-align {
    .leader-board-section {
        .leader-board-table-row {
            .round-table {
                min-width: 85px;
                @media screen and (max-width:$sm-screen) {
                    min-width: auto !important;
                } 
            }
            .total-table{
                @media screen and (max-width:$sm-screen) {
                    min-width: auto !important;
                } 
            }
            p{
                @media screen and (max-width:$sm-screen) {
                    text-align: right;
                } 
            }
        }
    }
}

ul.leaderboard-ul-list.player-leaderboard {
    .list-item{
        &::before,&::after{
            width: 100% !important;
        }
        
        .player-name{
            padding-top: 3px;
        }
    }
}