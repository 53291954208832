@import '../../index.scss';

.btn {
    color: $btn-color;
    font-size: 14px;
    border: none;
    outline: none;
    box-shadow: none;
    &.sidebar-btn{
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        background: none;
        color: $btn-color;
        white-space: nowrap;
        padding: 4px 20px 5px 20px;
        min-height: 30px;
        width: 180px;
        border-radius: 12px;
        margin-top: 5px;
        &.text-wrap {
            // width: unset;
            height: unset;
            align-items: start !important;
            text-align: left ;

        }
        &:hover {
            background-color: $white;
            background: $white;
            border-radius: 12px;
            color: $btn-color;
        }
        &:active:focus,
        &:active,
        &:focus {
            box-shadow: none;
            color: $btn-color;
            background-color: $white;
        }
        &.active {
            color: $btn-color;
            background-color: $white;
        }
    }
    &.footer-btn {
        font-size: 14px;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        &:hover {
            color: $btn-color;
        }
        &:active:focus,
        &:active,
        &:focus {
            box-shadow: none;
            color: $btn-color;
        }
        &.btn-primary {
            font-size: 14px;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            &:hover {
                color: $btn-color;
            }
            &:active:focus,
            &:active,
            &:focus {
                box-shadow: none;
                color: $btn-color;
            }
        }
    }
    &.btn-primary-custom {
        background: linear-gradient(225deg, $btn-color 0%, $btn-gradient 100%);
        border: none;
        color: $white;
        border-radius: 30px;
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        svg {
            fill: $white;
        }
        &:hover,
        &:active:focus,
        &:focus {
            background: $btn-color;
            border: none;
            box-shadow: none !important;
            transition-timing-function: ease-in-out;
            transition-duration: 150ms;
        }
    }
    &.btn-secondary-custom {
        border: 2px solid transparent;
        box-shadow: 2px 1000px 1px #00fecc inset;
        background-image: linear-gradient(rgba($white, 0), rgba($white, 0)),
            linear-gradient(225deg, $btn-color 0%, $btn-gradient 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-radius: 30px;
        font-size: 14px;
        color: $btn-color;
        white-space: nowrap;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        svg {
            fill: $btn-color;
        }
        &.for-mob-sidebar {
            box-shadow: 2px 1000px 1px $alert-primary inset;
        }
        &:hover {
            border: 2px solid $btn-gradient;
            box-shadow: 2px 1000px 1px $btn-gradient inset;
            background-image: none;
            background-origin: unset;
            background-clip: unset;
            color: $white;
            transition-timing-function: ease-in-out;
            transition-duration: 150ms;
            svg {
                fill: $white;
            }
        }
        &.active {
            border: 2px solid transparent;
            box-shadow: 2px 1000px 1px $primary-dark inset !important;
            background-image: linear-gradient(rgba($white, 0), rgba($white, 0)),
                linear-gradient(225deg, $btn-color 0%, $btn-gradient 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            color: $white !important;
            svg {
                fill: $white !important;
            }
            &:focus {
                box-shadow: 2px 1000px 1px $primary-dark inset;
            }
        }
        &:focus {
            box-shadow: 2px 1000px 1px $white inset;
            color: $btn-color;
            &:hover {
                border: 2px solid $btn-gradient;
                box-shadow: 2px 1000px 1px $btn-gradient inset;
                background-image: none;
                background-origin: unset;
                background-clip: unset;
                color: $white;
                transition-timing-function: ease-in-out;
                transition-duration: 150ms;
                svg {
                    fill: $white;
                }
            }
        }
    }
}