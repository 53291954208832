@import '../index.scss';


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $head-font;
    // color: $primary-dark;
}

.btn {
    color: $btn-color;
    &.btn-header {
        font-size: 12px;
        font-weight: $font-medium;
        border-radius: 20px;
        &.active {
            background-color: $primary-light;
            border-radius: 20px;
            color: $sidebar-color;
            &:hover {
                background-color: $primary-light;
                border-radius: 20px;
                color: $sidebar-color;
                cursor: default;
            }
        }
        &:hover {
            color: rgba($btn-color, 0.8);
            background-color: rgba($color: $primary-light, $alpha: 0.1);
        }
    }
}

.btn-primary {
    background: linear-gradient(225deg, $btn-color 0%, $btn-gradient 100%);
    border: none;
    color: $white;
    border-radius: 30px;
    font-size: 16px;
    padding: 9px 22px;
    svg {
        fill: $white;
    }
    &:hover,
    &:active:focus,
    &:focus {
        background: $btn-color;
        border: none;
        box-shadow: none !important;
    }
    &:focus {
        color: #fff;
    }
}

.btn {
    &.btn-outline-primary {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid $btn-color;
        color: $btn-color;
        border-radius: 30px;
        font-size: 16px;
        padding: 9px 22px;
        svg {
            fill: $btn-color;
        }
        &:hover,
        &:active:focus,
        &:focus {
            background: #7f18c2;
            border-color: #7f18c2;
            color: $white;
        }
    }
}


.btn-secondary {
    border: 2px solid transparent;
    box-shadow: 2px 1000px 1px $white inset;
    background-image: linear-gradient(rgba($white, 0), rgba($white, 0)),
        linear-gradient(225deg, $btn-color 0%, $btn-gradient 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 30px;
    font-size: 16px;
    color: $btn-color;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    svg {
        fill: $btn-color;
    }
    &:hover {
        border: 2px solid $btn-gradient;
        box-shadow: 2px 1000px 1px $btn-gradient inset;
        background-image: none;
        background-origin: unset;
        background-clip: unset;
        color: $white;
        svg {
            fill: $white;
        }
    }
    &:active:focus {
        border: 2px solid $primary-dark;
        box-shadow: 2px 1000px 1px $primary-dark inset;
        background-image: none;
        background-origin: unset;
        background-clip: unset;
        color: $white;
        svg {
            fill: $white;
        }
    }
    &:focus {
        border: 2px solid $primary-dark;
        box-shadow: 2px 1000px 1px $primary-dark inset !important;
        background-image: none;
        background-origin: unset;
        background-clip: unset;
        color: $white;
        svg {
            fill: $white;
        }
    }
}

.yakeety-footer{
    .btn-primary {
        svg{
            fill: #a60;
        }
    }
}

.team-sel-active {
    border: 2px solid $primary-dark !important;
    box-shadow: 2px 1000px 1px $primary-dark inset !important;
    background-image: none !important;
    background-origin: unset !important;
    background-clip: unset !important;
    color: $white !important;
}

.form-control {
    border: 1px solid $hover-color !important;
    border-radius: 25px !important;
    background-color: transparent !important;
    box-shadow: inset 3px 3px 0 rgba(170, 102, 0, 0.25) !important;
    &:focus {
        border-color: $hover-color;
        box-shadow: inset 3px 3px 0 rgba(170, 102, 0, 0.25) !important;
    }
    &.header-search-field {
        font-size: 12px;
        min-width: 250px;
        padding: 10px 20px;
        padding-right: 40px;
    }
    &:disabled{
        // background-color: #6c757d !important;
        border-color: #6c757d !important;
        color: #6c757d !important;
    }
}

.user-registration {
    width: 100vw;
    min-height: 100vh;
    // background-image: url(../components/assets/images/player/login_bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20%;
    background: linear-gradient(225deg, #18C27F 0%, #00FECC 100%);
    &.presentation-player-start {
        background-image: url(../components/assets/images/background.jpg);
        .signup {
            .signup-image {
                background-image: url(../components/assets/images/sec_bg.jpg);
            }
            // @media screen and (max-width:$sm-screen) {
            //     margin-bottom: 5rem;
            // }
            // @media screen and (max-width:$xs-screen) {
            //     margin-bottom: 0rem;
            // }
        }
    }
    &.player-invitation {
        h5 {
            color: $text-dark;
        }
        @media screen and (max-width: $md-screen) {
            padding: 2% 25%;
            overflow: hidden;
            height: 100%;
            .signup {
                .signup-form {
                    padding: 12%;
                    .btn {
                        width: 100%;
                    }
                }
                .signup-image {
                    min-height: 30vh;
                    background-position: top;
                }
            }
        }
        @media screen and (max-width: $sm-screen) {
            padding: 2% 15%;
            overflow: hidden;
            @media screen and (max-height: 500px) {
                position: unset;
                overflow: auto;
            }
            .player-inv-head {
                position: absolute;
                top: 20%;
                @media screen and (max-height: 500px) {
                    position: unset;
                    margin-top: 20px;
                }
            }
        }
        @media screen and (max-width: $xs-screen) {
            .signup {
                box-shadow: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 99;
                @media screen and (max-height: 500px) {
                    position: unset;
                }
                .signup-form {
                    padding: 12%;
                    .btn {
                        width: 100%;
                        &.dropdown-toggle{
                            &::after {
                                @media screen and (max-width:$xs-screen) {
                                    margin-left: 5em;
                                }
                            }
                        }
                    }
                    
                }
                .signup-image {
                    min-height: 30vh;
                    background-position: top;
                }
            }
        }
    }
    @media screen and (min-width: $md-screen) and (max-width: $xl-screen) {
        padding: 12%;
        overflow: hidden;
    }
    @media screen and (max-width: $md-screen) {
        padding: 5%;
        overflow: hidden;
    }
    @media screen and (max-width: $xs-screen) {
        overflow-y: auto;
    }
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        padding: 5% !important;
    }
    .signup {
        border-radius: 12px;
        background-color: $white;
        box-shadow: 4px 4px 0 $primary-color;
        position: relative;
        z-index: 9;
        .signup-form {
            padding: 6%;
            padding-bottom: 3%;
            .v-btn {
                right: 5px;
                top: 1px;
                color: $btn-color;
            }
            .dropdown{
                .dropdown-menu {
                    max-height: 15rem;
                    overflow-y: auto;
                }
            }
        }
        .signup-image {
            background-image: url(../components/assets/images/player/login_img.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0 12px 12px 0;
            min-height: 70vh;
            &.presentation-start-game-img {
                min-height: unset;
            }
            // @media screen and (min-width: 2000px) {
            //     width: 33.333% !important;
            // }
        }
        .sign-link a {
            font-size: 14px;
            text-decoration: none;
            &.forget-p-word {
                color: $black;
            }
            &.mail-req {
                color: $btn-color;
            }
        }
    }
}