@import '../index.scss';

.presentation-start-view {
    .main-content {
        padding-top: 100px;
        padding-bottom: 10px;
        @media(min-width:1230px) {
            padding-top: 120px;
        }
    }
    .game-section-rocket {
        // margin-top: 53px;
        .rocket {
            margin-top: -50px;
            .rocket-smoke {
                height: 10px;
            }
            .rocket-svg {
                margin-left: -120px;
                width: 180px;
                height: 10px;
                svg, img {
                    height: 42px;
                    margin-top: -30px;
                }
            }
            &.blue {
                .rocket-smoke {
                    width: 75%;
                    background: linear-gradient(90deg, rgba(98, 52, 197, 0.5) 0.65%, #6234C5 3.9%, rgba(98, 52, 197, 0.25) 50.64%);
                    // animation: rocketmove1 5s ease;
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #6234C5;
                        }
                    }
                }
            }
            &.green {
                .rocket-smoke {
                    width: 60%;
                    background: linear-gradient(90deg, rgba(133, 197, 52, 0.5) 0.65%, #85C534 3.9%, rgba(133, 197, 52, 0.25) 50.64%);
                    // animation: rocketmove2 5s ease;
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #85C534;
                        }
                    }
                }
            }
            &.red {
                .rocket-smoke {
                    width: 30%;
                    background: linear-gradient(90deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);
                    // animation: rocketmove3 5s ease;
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #C53434;
                        }
                    }
                }
            }
        }
        .game-player-position{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }
}

// @keyframes rocketmove1 {
//     from {width: 0%}
//     to {width: 75%;}
// }

// @keyframes rocketmove2 {
//     from {width: 0%}
//     to {width: 60%;}
// }

// @keyframes rocketmove3 {
//     from {width: 0%}
//     to {width: 30%;}
// }

.game-player-position {
    &.active-animation {
        .rocket-smoke {
            animation-play-state: running;
        }
    }
    &.pause-animation {
        .rocket-smoke {
            animation-play-state: paused;
        }
    }
}

.leader-board-section{
    .leader-board-table-row{
        @media screen and (max-height:700px) {
            // height: 28px;
        }
    }
}