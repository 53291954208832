@import '../../index.scss';

.leader-board-section {
    color: $white;

    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape) {
        padding-bottom: 30px !important;
    }

    .leader-board-table-header {
        font-size: 14px;

        // width: 100%;
        p {
            margin-bottom: 5px;
        }
        .round-teb-head{
            &.long-round{
                p{
                    max-width: 35px;
                }
                .last-round, .last-total{
                    max-width: initial;
                }
            }
        }
        .podium-thead * {
            min-width: 39px;
            &:last-child{
                min-width: 75px;
            }
        }
    }

    .leader-board-table-row {
        border-radius: 30px;
        padding: 1px 0;
        font-size: 14px;
        // width: 100%;
        // animation: table-expand 5s ease;
        overflow: hidden;
        font-family: $quest-font;

        // @media screen and (max-width: $sm-screen) {
        //     animation: none;
        // }
        .correct-answer {
            width: 100px;
        }
        .fast-correct-answer-span{
            max-width: 147px;
        }

        .fast-correct-answer {
            width: 150px;
        }

        .points {
            width: 90px;
        }

        .round-points {
            width: 95px;
        }

        .round-table {
            min-width: 40px;
        }

        .total-table {
            min-width: 30px;
        }

        .xtra-border {
            border-bottom: 1px solid;
            border-top: 1px solid;

            .hide-for-anim {
                opacity: 1;
                // animation: hide-for-anim 8s ease;
                // @media screen and (max-width: $sm-screen) {
                //     animation: none;
                // }
            }
        }

        .player-name,
        .player-name-long {
            width: 100%;
            max-width: 120px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .player-name-long {
            max-width: 250px;
            &.long-name-v2{
                max-width: 125px;
                width: 100%;
            }
        }
        

        .tr-count {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            border-radius: 50%;
            font-family: $sub-font;
            background: --element-color;
        }

        &.blue {
            background: linear-gradient(90deg, rgba(98, 52, 197, 0.5) 0.65%, #6234C5 3.9%, rgba(98, 52, 197, 0.25) 50.64%);

            .xtra-border {
                border-color: #6234C5;
            }

            .tr-count {
                // background-color: #6234C5;
            }
        }

        &.green {
            background: linear-gradient(90deg, rgba(133, 197, 52, 0.5) 0.65%, #85C534 3.9%, rgba(133, 197, 52, 0.25) 50.64%);

            .xtra-border {
                border-color: #85C534;
            }

            .tr-count {
                background-color: #85C534;
            }
        }

        &.lightblue {
            background: linear-gradient(90deg, rgba(52, 110, 197, 0.5) 0.65%, #346EC5 3.9%, rgba(52, 110, 197, 0.25) 50.64%);

            .xtra-border {
                border-color: #346EC5;
            }

            .tr-count {
                background-color: #346EC5;
            }
        }

        &.red {
            background: linear-gradient(90deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);

            .xtra-border {
                border-color: #C53434;
            }

            .tr-count {
                background-color: #C53434;
            }
        }

        &.cyan {
            background: linear-gradient(90deg, #34c5a280 0.65%, #34C5A2 3.9%, rgba(52, 197, 162, 0.25) 50.64%);

            .xtra-border {
                border-color: #34C5A2;
            }

            .tr-count {
                background-color: #34C5A2;
            }
        }
        .podium-tbody {
            display: flex;
            justify-content: flex-start;
            span {
                min-width: 40px;
                padding-left: 6px;
                padding-right: 6px;
            }
        }
    }
    .long-name-v2{
        max-width: 125px;
        width: 100%;
    }
}

// @keyframes table-expand {
//     from {width: 30%;}
//     to {width: 100%;}
// }

// @keyframes hide-for-anim {
//     from {opacity: 0;}
//     to {opacity: 1;}
// }

.leader-board-custom-align {
    .leader-board-section {
        .leader-board-table-row {
            .round-table {
                min-width: 85px;

                &.for-p-round-c {
                    min-width: 40px;
                }
            }

            @media screen and (max-width:$sm-screen) {
                max-width: 35px;
                min-width: 26px;
            }
        }
    }
}

.verticle-table{
    .leader-board-table-row{
        @media screen and (max-width:$sm-screen) {
            padding-bottom: 10px;
            &::before, &::after{
                background-color: var(--element-color);
                content: "";
                height: calc(100% - 20px);
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1px;
            }
            &::before{
                right:0;
            }
            &::after{
                left:0;
            }
        }
    }
}

$tweener: 1.5s;
// $offset: 0.225;
$offset: .065;
$columnDelay: .5;

ul.leaderboard-ul-list {
    list-style: none;

    li.team {
        margin: .1em 0;
        display: flex;
        align-items: center;
    }

    .list-item {
        padding: 3px 0 0;
        min-height: 25px;
        //   background-color: rgb( 200, 200, 255);
        animation-name: fadeInAnimation;
        animation-duration: #{ $tweener };
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        // animation-play-state: false;
        opacity: 0;
        position: relative;

        &:first-of-type,
        &:last-of-type {
            font-weight: 700;
        }

        // &:first-of-type{
        //     &::before,&::after{
        //         width: 100% !important;
        //     }
        // }
        // &:last-of-type {
        //     &::before,&::after{
        //           width: 88%;
        //       }
        //   }
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 1px;
            width: 100%;
            background-color: var(--element-color);
        }

        &::after {
            top: initial !important;
            bottom: 0;
        }
        p{
            font-size: 17px;
            line-height: 1.2;
            @media screen and (max-width:$xs-screen) {
                font-size: 13px;
            }
        }
    }

    p {
        @media screen and (max-width : $xs-screen) {
            font-size: 12px;
            padding-top: 4px;
        }
    }
}



@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



$teamCount: 10;

@for $itemIndex from 1 through 20 {
    @for $teamIndex from 1 through $teamCount {
        #team#{$teamIndex} .item#{$itemIndex} {
            animation-delay: #{ ( $tweener / 2 ) * $offset * ( (( $itemIndex - 1 ) * ( $teamCount + $columnDelay )) + ($teamIndex*2) ) };
            font-family: rgb(var(--element-color-rgb));
            // background: linear-gradient(90deg, rgba(var(--podium-leaderboard-color-#{$teamIndex}), 0.5) 0.65%, rgba(var(--podium-leaderboard-color-#{$teamIndex})) 3.9%, rgba(var(--podium-leaderboard-color-#{$teamIndex}), 0.25) 90.64%);
            background: linear-gradient(90deg, rgb(var(--element-color-rgb), 0.5) 0.65%, rgb(var(--element-color-rgb)) 3.9%, rgba(var(--element-color-rgb), 0.25) 90.64%);
            @if $itemIndex>2 {
                background: rgba(var(--element-color-rgb), 0.25);
            }
            .tr-count{
                background: rgb(var(--element-color-rgb));
            }
        }
    }
}

.player-point-adjust {
    span {
        flex-grow: 1;
        min-width: 28%;
        display: inline-block;

        div {
            width: unset !important;
        }
    }

    span:nth-child(3) {
        min-width: 20%;
    }

    span:last-child {
        min-width: 16%;
        border-radius: 0 30px 30px 0;
    }
}

.player-head-adjust {
    div {
        flex-grow: 1;
        min-width: 16%;
    }
}

.player-head-round {
    p {
        flex-grow: 1;
        min-width: 18%;
    }
}

.player-head {
    p {
        min-width: 23%;
    }
}

.player-point-round {
    span {
        flex: 1;
    }
}