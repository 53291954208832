@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css);

.preview-main{

.presentation-start-view.preview-round {
    .main-content {
        padding: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        .orbit-path-bg {
            .ob-p-position {
                bottom: -285px;
                right: -257px;
                @media screen and (max-width: 1200px) {
                    transform: scale(0.9);
                }
                @media screen and (max-width: 990px) {
                    transform: scale(.8);
                    right: -227px;
                }
                @media screen and (max-width: 575px) {
                    transform: scale(0.6);
                    bottom: -280px;
                    right: -170px;
                }
            }
        }
        .game-view-section {
            transform: scale(0.8) !important;
            transform-origin: center !important;
            @media screen and (max-width: 990px) {
                transform: scale(0.62) !important;
            }
            @media screen and (max-width: 575px) {
                transform: scale(0.45) !important;
            }
        }
    }
    .planet-resize ~ .footer-section {
        .footer-bg {
            .footer-overlay-img {
                bottom: 0;
                .ho-logo-section-footer-logo {
                    top: 25px;
                    @media screen and (max-width: 990px) {
                        top: 21px;
                        width: 80px;
                    }
                    @media screen and (max-width: 768px) {
                        top: 23px;
                        width: 80px;
                    }
                }
            }
        }
    }
    .long-footer {
        max-width: 500px;
        width: 500px;
    }
}
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview-main{
.skin-car {
    background-image:url(../../assets/skin/car/round/DsukRush/bg_no_car.jpg) !important;
    background-position: center !important;
    .presentation-start-view.preview-round {
        .long-header {
            .defualt-long-cloud {
                .long-top {
                    img {
                        width: 170px !important;
                    }
                }
            }
        }
        &.main-bg {
            .long-header {
                .defualt-long-cloud {
                    .long-top {
                        img {
                            width: 170px !important;
                        }
                    }
                }
            }
        }

    }
    &.preview-podium {
        .presentation-start-view {
            .main-content {
                .game-view-position-sec {
                    .game-position-view.podium-winners {
                        height: 300px;
                        margin-top: initial;
                        position: absolute !important;
                        width: 100%;
                        bottom: 0;
                        background-size: cover;
                    }
                }
            }
        }
        &.main-bg {
            .presentation-start-view {
                .main-content {
                    .game-view-position-sec {
                        .game-position-view {
                            &.podium-winners {
                                height: 300px;
                                margin-top: initial;
                                position: absolute !important;
                                width: 100%;
                                bottom: 0;
                                background-size: cover;
                            }
                        }
                    }
                }
            }
        }
        #podium-visual, .main-bg #podium-visual {
            font-size: 0.05vw !important;
        }
    }
    
    .game-player-position {
        .rocket {
            .whole-winner-frames {
                .round-winner {
                    right: 285px;
                    top: -260px;
                    width: 100px !important;
                    height: 100px !important;
                    background: #346EC5;
                    animation: show-delay 1.5s ease 10s forwards;
                    box-shadow: 0px 1px 2px #000000, inset 0px 0px 10px rgba(255, 255, 255, 0.5);
                    opacity: 0;
                    i {
                        font-size: 35px;
                    }
                }
            }
            &:nth-child(2) {
                .whole-winner-frames {
                    .round-winner {
                        right: 245px;
                        top: -225px;
                    }
                }
            }
            &:nth-child(3) {
                .whole-winner-frames {
                    .round-winner {
                        right: 165px;
                        top: -190px;
                    }
                }
            }
        }
    }
}
}


.preview-main{

.skin-astro {
    .game-view-position-sec {
        top: 0;
        padding-top: 50px;
        overflow: visible;
        @media screen and (max-width: 768px) {
            padding-top: 20px;
        }
        .game-position-view {
            overflow: visible;
            .gp-bg {
                top: 50px;
                left: -200px;
                .gp-img-section {
                    @media screen and (max-width: 991px) {
                        width: 40%;
                    }
                }
            }
        }
        .winning-positions {
            transform: scale(.6);
            transform-origin: top right;
            @media screen and (max-width: 768px) {
                transform: scale(0.8);
                -webkit-transform-origin-x: calc(100% - 220px);
            }
        }
    }
    .game-player-position {
        .rocket {
            .whole-winner-frames {
                .round-winner {
                    right: 90px;
                    top: 90px;
                    width: 70px !important;
                    height: 70px !important;
                    background: #346EC5;
                    animation: show-delay 1.5s ease 4s forwards;
                    box-shadow: 0px 1px 2px #000000, inset 0px 0px 10px rgba(255, 255, 255, 0.5);
                    opacity: 0;
                    i {
                        font-size: 35px;
                    }
                }
            }
            &:nth-child(2) {
                .whole-winner-frames {
                    .round-winner {
                        right: 90px;
                        top: 90px;
                    }
                }
            }
            &:nth-child(3) {
                .whole-winner-frames {
                    .round-winner {
                        right: 90px;
                        top: 90px;
                    }
                }
            }
        }
    }
}
}



@keyframes show-delay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}