
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');


$sub-font: 'Fredoka One', cursive;

$sm-screen: 767.98px;

$team-color: var(--team-color);

$backDropBlur:  blur(2px);

$signupWidth: 920px;
.backdropShadow{
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: $backDropBlur;
    border-radius: 0.25em;
}
// $assetPath :"path";

@function get($var) {
    @return map-get($custom-properties, $var);
  }
  $custom-properties: (
    'navbar-transparent': true,
  );
  
  @each $key, $value in $custom-properties {
    :root {
      --#{$key}: #{$value};
    }
  }


    // BACKGROUNDS
    // NEUTRAL = V3
    .main-bg.skin-healthdash.player-skin,
    .main-bg.skin-healthdash.player-skin.background_v1_undefined {
        background: url('../player/background/background_v3.jpg');
        background-size: cover;
        .user-registration.presentation-player-start {
            background: none;
            .signup {
                max-width: $signupWidth;
                .signup-image {
                    background: url('../player/background/background_v2.jpg');
                    background-position: 87.5% 12.5%;
                    img {
                        max-width: 300px;
                        max-height: calc(100% - 160px);
                    }
                }
            }
            img.skin-logo,
            .presentation-content img {
                max-width: 300px;
                max-height: calc(100% - 160px);
            }
        }
        &.background_v1_34C5A2{
            background-image: url(../player/background/background_v1_34C5A2.jpg) !important;
        }
        &.background_v2_34C5A2{
            background-image: url(../player/background/background_v2_34C5A2.jpg) !important;
        }
        &.background_v3_34C5A2{
            background-image: url(../player/background/background_v3_34C5A2.jpg) !important;
        }
        &.background_v1_85C534{
            background-image: url(../player/background/background_v1_85C534.jpg) !important;
        }
        &.background_v2_85C534{
            background-image: url(../player/background/background_v2_85C534.jpg) !important;
        }
        &.background_v3_85C534{
            background-image: url(../player/background/background_v3_85C534.jpg) !important;
        }
        &.background_v1_346EC5{
            background-image: url(../player/background/background_v1_346EC5.jpg) !important;
        }
        &.background_v2_346EC5{
            background-image: url(../player/background/background_v2_346EC5.jpg) !important;
        }
        &.background_v3_346EC5{
            background-image: url(../player/background/background_v3_346EC5.jpg) !important;
        }
        &.background_v1_6234C5{
            background-image: url(../player/background/background_v1_6234C5.jpg) !important;
        }
        &.background_v2_6234C5{
            background-image: url(../player/background/background_v2_6234C5.jpg) !important;
        }
        &.background_v3_6234C5{
            background-image: url(../player/background/background_v3_6234C5.jpg) !important;
        }
        &.background_v1_C59C34{
            background-image: url(../player/background/background_v1_C59C34.jpg) !important;
        }
        &.background_v2_C59C34{
            background-image: url(../player/background/background_v2_C59C34.jpg) !important;
        }
        &.background_v3_C59C34{
            background-image: url(../player/background/background_v3_C59C34.jpg) !important;
        }
        &.background_v1_C534A5{
            background-image: url(../player/background/background_v1_C534A5.jpg) !important;
        }
        &.background_v2_C534A5{
            background-image: url(../player/background/background_v2_C534A5.jpg) !important;
        }
        &.background_v3_C534A5{
            background-image: url(../player/background/background_v3_C534A5.jpg) !important;
        }
        &.background_v1_C5344E{
            background-image: url(../player/background/background_v1_C5344E.jpg) !important;
        }
        &.background_v2_C5344E{
            background-image: url(../player/background/background_v2_C5344E.jpg) !important;
        }
        &.background_v3_C5344E{
            background-image: url(../player/background/background_v3_C5344E.jpg) !important;
        }
        &.background_v1_C53434{
            background-image: url(../player/background/background_v1_C53434.jpg) !important;
        }
        &.background_v2_C53434{
            background-image: url(../player/background/background_v2_C53434.jpg) !important;
        }
        &.background_v3_C53434{
            background-image: url(../player/background/background_v3_C53434.jpg) !important;
        }

        &.background_v1_B84F3E{
            background-image: url(../player/background/background_v1_B84F3E.jpg) !important;
        }
        &.background_v2_B84F3E{
            background-image: url(../player/background/background_v2_B84F3E.jpg) !important;
        }
        &.background_v3_B84F3E{
            background-image: url(../player/background/background_v3_B84F3E.jpg) !important;
        }

        &.background_v1_B99149{
            background-image: url(../player/background/background_v1_B99149.jpg) !important;
        }
        &.background_v2_B99149{
            background-image: url(../player/background/background_v2_B99149.jpg) !important;
        }
        &.background_v3_B99149{
            background-image: url(../player/background/background_v3_B99149.jpg) !important;
        }

        &.background_v1_A4AD42{
            background-image: url(../player/background/background_v1_A4AD42.jpg) !important;
        }
        &.background_v2_A4AD42{
            background-image: url(../player/background/background_v2_A4AD42.jpg) !important;
        }
        &.background_v3_A4AD42{
            background-image: url(../player/background/background_v3_A4AD42.jpg) !important;
        }

        &.background_v1_3FC26F{
            background-image: url(../player/background/background_v1_3FC26F.jpg) !important;
        }
        &.background_v2_3FC26F{
            background-image: url(../player/background/background_v2_3FC26F.jpg) !important;
        }
        &.background_v3_3FC26F{
            background-image: url(../player/background/background_v3_3FC26F.jpg) !important;
        }

        &.background_v1_408BD1{
            background-image: url(../player/background/background_v1_408BD1.jpg) !important;
        }
        &.background_v2_408BD1{
            background-image: url(../player/background/background_v2_408BD1.jpg) !important;
        }
        &.background_v3_408BD1{
            background-image: url(../player/background/background_v3_408BD1.jpg) !important;
        }

        &.background_v1_5F4CA3{
            background-image: url(../player/background/background_v1_5F4CA3.jpg) !important;
        }
        &.background_v2_5F4CA3{
            background-image: url(../player/background/background_v2_5F4CA3.jpg) !important;
        }
        &.background_v3_5F4CA3{
            background-image: url(../player/background/background_v3_5F4CA3.jpg) !important;
        }

        &.background_v1_5F4CA3{
            background-image: url(../player/background/background_v1_5F4CA3.jpg) !important;
        }
        &.background_v2_5F4CA3{
            background-image: url(../player/background/background_v2_5F4CA3.jpg) !important;
        }
        &.background_v3_5F4CA3{
            background-image: url(../player/background/background_v3_5F4CA3.jpg) !important;
        }
    }


    // HEADERS
    .main-bg.skin-healthdash.player-skin{
        
        .ho-logo-section-header-logo {
            width: 150px;
            @media screen and (max-width: $sm-screen) {
                width: 100px;
            }
        }
    } 

.skin-healthdash{
    // PRESENTATION TEXT 
    .intro-text {
        @extend .backdropShadow;
        padding-left: 1em;
        padding-right: 1em;
    }





    .skin-car {
        background-image: url(../round/DsukRush/bg_no_car.jpg);
        &.custom-bg-2{
            @if var(--team-color) == var(--team-color) {
                    background-image: url(../player/background/Teams-3.jpg);
                    font-family:"get(var(--team-color))" ;
                }
            @else {
                background-color: #000;
                font-family:get(var(--team-color)) ;

            }
        }
        .user-registration{
            &.presentation-player-start {
                background-image: none;
                .signup {
                    .signup-image {
                        background-image: url(../round/DsukRush/bg_car.jpg);
                        .presentation-content {
                            h4 {
                                font-family: "RacingSans";
                            }
                        }
                    }
                }
            }
            .signup{
                .signup-form {
                    label {
                        font-family: Space Mono;
                        font-size: 14px;
                    }
                    .player-inv-head {
                        h5, h1, h3 {
                            font-family: "RacingSans", cursive;
                        }
                    }
                    .player-name{
                        font-family: "RacingSans", cursive;
                    }
                }
            }
        }
        button.btn {
            font-family: Space Mono;
            font-size: 14px;
            &.ans-send-btn{
                font-size: 24px;
                background: $team-color;
            }
        }
        .main-content {
            .it-heading{
                .title-sec{
                    background-color: $team-color;
                    display: inline-block;
                    padding: 2px 15px;
                    margin-bottom: .5rem;
                    transform: skewX(-15deg);
                    h1{
                        margin-bottom: 0;
                        transform: skewX(15deg);
                    }
                }
            }
            .it-content{
                font-family: Space Mono;
                font-size: 30px;
            }
            .pq-head {
                font-family: "RacingSans";
                font-size: 35px;
                display: inline-block;
                padding: 0px 15px;
                background-color: $team-color;
                transform: skewX(-15deg);
                span{
                    transform: skewX(15deg);
                    display: inline-block;
                }
            }
        }
        .player-questions {
            .main-content {
                .pq-whole-section {
                    .pq-head{
                        width: auto;
                        font-size: 35px;
                    }
                    .answer-option-section{
                        h6{
                            font-family: $sub-font;
                        }
                    }
                    .answer-options{
                        font-family: 'Space Mono';
                    }
                }
            }
        }

    }


    .modal {
        background: linear-gradient(225deg, $team-color 0%, rgba(0,0,0,.5) 100%) !important;
        z-index: 99999;
    }
}