@import '../../index.scss';

.yakeety-sidebar {
    position: fixed;
    top: 66px;
    z-index: 99999;
    background-color: $sidebar-color;
    width: 240px;
    height: calc(100vh - 66px);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: $form-border-color;
    }
    &::-webkit-scrollbar-thumb {
        background: $btn-color;
        border-radius: 30px;
    }
    @media screen and (max-width: $sm-screen) {
        width: 100vw;
        height: 100vh;
        top: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        .mob-sidebar {
            .msh-team-logo {
                width: 75px;
            }
            .msh-darkmode-button {
                .toggle-input {
                    position: relative;
                    top: 3px;
                    width: 40px;
                    height: 20px;
                    -webkit-appearance: none;
                    -webkit-appearance: none;
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;
                    border-radius: 20px;
                    transition: background-color 300ms linear;
                    border: 2px solid #aa6600;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 12px;
                        height: 12px;
                        top: 2px;
                        left: 2px;
                        border-radius: 20px;
                        background-color: #aa6600;
                        transition: left 300ms;
                    }
                    &:checked {
                        background: #7f18c2;
                        border: none;
                        &::before {
                            left: 24px;
                            background-color: #ffffff;
                            top: 4px;
                        }
                    }
                }
                svg {
                    fill: $white;
                }
            }
            .controller-view {
                border-radius: 12px;
                background-color: $alert-primary;
                border: 2px solid $alert-primary;
                .game-stats-section {
                    background-color: $sidebar-color;
                    border-radius: 12px;
                    overflow: hidden;
                    .game-start-section {
                        background-color: $primary-dark;

                        h4 {
                            color: $white;
                        }
                    }
                    .game-status {
                        .info-section {
                            color: $form-border-color;
                            cursor: pointer;
                        }
                        h4 {
                            color: $white;
                        }
                    }
                }
            }
        }
        .bs-divider {
            border-bottom: 1px solid $primary-dark;
        }
        .sb-mb-footer-logo {
            width: 25px;
        }
    }
    &.light-mode-active {
        @media screen and (max-width: $sm-screen) {
            background-color: $white;
            .msh-darkmode-button {
                svg {
                    fill: $sidebar-color;
                }
            }
            .controller-view {
                border-radius: 12px;
                background-color: $alert-primary;
                border: 2px solid $alert-primary;
                .game-stats-section {
                    background-color: $white;
                    border-radius: 12px;
                    overflow: hidden;
                    .game-start-section {
                        background-color: $primary-dark;

                        h4 {
                            color: $white;
                        }
                    }
                    .game-status {
                        .info-section {
                            color: $form-border-color;
                            cursor: pointer;
                        }
                        h4 {
                            color: $text-dark;
                        }
                    }
                }
            }
        }
    }
}

.full-screen-game .yakeety-sidebar {
    top: 10px;
    height: calc(100vh - 10px);
}