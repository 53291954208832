@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

@font-face {
    font-family: "RacingSans";
    src: url("../Fonts/RacingSans.otf") format("opentype"),
        url("../Fonts/RacingSans.woff") format("woff"),
        url("../Fonts/RacingSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$sub-font: "RacingSans";

$backDropBlur:  blur(2px);
.backdropShadow{
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: $backDropBlur;
    border-radius: 0.25em;
}
.skin-healthdash{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $sub-font;
    }
}


// BACKGROUNDS 
// NEUTRAL
.main-bg.skin-healthdash {
    background: url('../player/background/background_v3.jpg');
    background-size: cover;
    &.custom-bg-2 {
        background: url('../player/background/background_v1.jpg');
    }
}



// PRESENTATION TEXT 
.skin-healthdash .presentation-question {
    .text-bg {
        @extend .backdropShadow;
    }
}




// QUESTION LEADERBOARD (race) 
.skin-healthdash {
    .long-header .defualt-long-cloud .long-top {
        width: 200px;
    }
    background-image: url('../player/background/background_v3.jpg');
    background-size: cover;
    background-position: center;

    &.custom-bg-4 {
        // background-image: url(../car-bg.jpg);

        // background-position: top center;
        // background: #85e898;
        position: relative;
        background: none;
        // background-image: url(../question/bg-wall-bottom.png);
        // background-position: 0 calc(100% - 33vh);
        // background-position: 0 calc(100% - 300px);
        // background-repeat: repeat-x;
    }

    &.custom-bg-2 {
        background: url('../player/background/background_v1.jpg');
    }
    &.custom-bg-5 {
        background-image: none;
        background-color: #f045ff;
        background: radial-gradient(64.11% 113.98% at 49.59% 28.71%,#e5ff00 0,#f045ff 100%);
    }

    .presentation-question {
        .text-bg {
            background-color: rgba(0, 0, 0, 0.25);
            font-family: 'Space Mono', monospace;
            font-size: 30px;
        }

        .main-content {
            .pq-head {
                font-family: $sub-font;
                font-size: 42px;
            }

            .graph-bar-section {
                .ps-trans-background {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &:not(.player-skin) .presentation-start-view .main-content {
        padding-top: 90px;
        display: block !important;;
        flex-direction: unset;
        justify-content: unset;
    }
    .presentation-start-view {
        .main-content {
            padding-top: 90px;
            display: block;
            flex-direction: unset;
            justify-content: unset;
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;


            .game-player-position,
            .moving-bg:not(.cloud):after{
                height: 34.4vh !important;
                // min-height: 344px;
                max-height: none;
            }
            
            .game-section-rocket {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding-bottom: 50px;



                .game-player-position {
                    justify-content: space-around;
                    // background: #bfa3a2;
                    background: none;
                    // background-image: url(../road.jpg);
                    // max-height: 344px;
                    // background-position: bottom;
                    // background-size: cover;
                    position: relative;
                    z-index: 99;

                    // &.game-player-position::before {
                    //     content: "";
                    //     position: absolute;
                    //     width: 100%;
                    //     height: 2px;
                    //     left: 0;
                    //     top: 10px;
                    //     background-color: #f9c241;
                    //     z-index: 9;
                    // }
                    // &.path-section {
                    //     margin-top: -30px;
                    //     .rocket{
                    //         .rocket-svg{
                    //             img{
                    //                 height: 40px;
                    //             }
                    //         }
                    //     }
                    // }

                    .rocket {
                        height: 280px;
                        position: relative;
                        z-index: 99;

                         &::before {
                            display: none;
                         }

                        &:last-child {
                            &::before {
                                display: none;
                            }
                        }

                        .rocket-smoke {
                            opacity: 0;
                        }

                        .rocket-svg {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            z-index: 9;

                            img {
                                height: 180px;
                                height: 280px;
                                width: auto;
                                display: block;
                            }
                        }
                    }
                }
            }
            
            .moving-bg {
                position: absolute;
                top: 0;
                width: 100vw;
                // height: calc(100vh - 450px);
                height: 100vh;
                background-image: none;
                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: initial;
                    overflow: hidden;
                    background-repeat: repeat-x;
                    background-size: 1440px;

                    width: 2880px;
                    animation: city 1.75s linear infinite;
                    @media screen and (min-width: 1441px ) {
                        width: 8640px;
                        animation: city 4.5s linear infinite;
                    }
                }
                &:not(.cloud) {
                    z-index: 9;
                    &:after{ // Ground
                        bottom: 0;
                        background-color: #bfa3a2;
                        background-image: url(../question/bg-ground.png);
                        background-position-y: 30%;
                        z-index: 1;
                    }
                    &:before { // Wall
                        bottom: 321px; // 344 - 23
                        bottom: calc(34.4vh - 23px);
                        height: 401px;
                        background-image: url(../question/bg-wall.png);
                        z-index: 2;
                        animation: city 2s linear infinite;
                        @media screen and (min-width: 1441px ) {
                            animation: city 5.14s linear infinite;
                        }
                    }
                }
                &.cloud{
                    z-index: 19;
                    &:before{
                        top: 0;
                        height: 1024px;
                        background-image: url(../question/bg-ceiling2.png);
                        animation: city 1.65s linear infinite;
                        @media screen and (min-width: 1441px ) {
                            animation: city 4.09s linear infinite;
                        }
                    }
                }
            }
            // .moving-bg::before,
            // .moving-bg::after,
            // .moving-bg.back::before,
            // .moving-bg.back::after,
            // .moving-bg.cloud::before,
            // .moving-bg.cloud::after {
            //     // @media screen and (max-height : 900px) {
            //     //     height: calc(100vh - 380px);
            //     // }
            // }


            .moving-bg.back::after {
                display: none;
            }

            .moving-bg.back::before {
                display: none;
            }




            @keyframes city {
                100% {
                    transform: translateX(-50%);
                }
            }

            .game-view-section {

                // transform: scale(0.75);
                .sat-orbit-hide {
                    .sat-planet-0 {
                        bottom: initial;
                        left: -80px;
                        width: 220px;
                        top: 80px;
                        z-index: 99;
                    }

                    .sat-orbit-hide-custom-width {
                        width: 980px;

                        .sat-orbit-line {
                            height: 340px;

                            .svg-condition {
                                display: block;
                            }

                            .sat-orbit {
                                width: 970px;
                                height: 340px;
                                border: none;
                                border-radius: 0%;

                                .road-track {
                                    svg {
                                        .road-track {
                                            stroke-dasharray: 0 5000;
                                            // animation: 1s orbitanimation1;
                                        }

                                        // .round-road{
                                        //     display: none;
                                        // }
                                        // path.round-road:nth-child(2){
                                        //     display: block;
                                        //     stroke-dasharray: 500 5000;
                                        // }
                                    }
                                }
                            }

                            .soh-mask {
                                left: 0;
                                width: 970px;
                                height: 340px;
                                top: 3px;

                                .sat-orbit-highlight {
                                    border-radius: 0%;
                                    transform: rotate(0deg) !important;
                                    border: none;

                                    svg {
                                        display: none;
                                    }
                                }

                                &.first {
                                    .sat-orbit-highlight {
                                        svg {
                                            display: block;

                                            .road-track {
                                                stroke-dasharray: 300 5000;
                                            }
                                        }
                                    }
                                }

                                &.second {
                                    .sat-orbit-highlight {
                                        svg {
                                            display: block;

                                            .road-track {
                                                stroke-dasharray: 600 5000;
                                            }
                                        }
                                    }
                                }

                                &.third {
                                    .sat-orbit-highlight {
                                        svg {
                                            display: block;

                                            .road-track {
                                                stroke-dasharray: 1000 5000;
                                            }
                                        }
                                    }
                                }

                                &.fourth {
                                    .sat-orbit-highlight {
                                        svg {
                                            display: block;

                                            .road-track {
                                                stroke-dasharray: 1500 5000;
                                            }
                                        }
                                    }
                                }

                                &.fifth {
                                    .sat-orbit-highlight {
                                        svg {
                                            display: block;
                                            .road-track {
                                                stroke-dasharray: 2500 5000;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .sat-planet-com {
                        border: none;
                        border-radius: 0;
                        $cityWidth: 165px;
                        width: $cityWidth;
                        height: ($cityWidth / 11 * 10);


                        .planet-with-action {
                            // margin-top: -80px;
                            width: 100%;
                            height: 100%;
                            img{
                                position: absolute;
                                z-index: 2;
                                left:0;
                                right: 0;
                            }
                            .round-won {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                z-index: 1;
                                display: block !important;
                                width: 100%;
                                height: auto;
                                top: initial;
                                animation: roundShow 1s ease-in-out 4s forwards;
                                opacity: 0;
                                svg {display: none;}
                                width: 100%;
                                height: 100%;
                                background-size: 100%;


                            }
                        }
                        &.closed{
                            .round-won{
                                display: none !important;
                            }
                        }

                        &.sat-planet-1 {
                            top: 0px;
                            left: 250px;
                            z-index: 99;
                            .planet-with-action .round-won{
                                background-image: url(../round/map/rounds/round1-won.svg);
                            }
                        }

                        &.sat-planet-2 {
                            top: -10px;
                            right: 180px;
                            left: inherit;
                            z-index: 99;
                            .planet-with-action .round-won{
                                background-image: url(../round/map/rounds/round2-won.svg);
                            }
                        }

                        &.sat-planet-3 {
                            left: initial;
                            top: 160px;
                            right: -70px;
                            z-index: 99;
                            .planet-with-action .round-won{
                                background-image: url(../round/map/rounds/round3-won.svg);
                            }
                        }

                        &.sat-planet-4 {
                            left: initial;
                            top: 190px;
                            right: 260px;
                            z-index: 99;
                            .planet-with-action .round-won{
                                background-image: url(../round/map/rounds/round4-won.svg);
                            }
                        }

                        &.sat-planet-5,
                        &.sat-planet-halo {
                            // top:140px;
                            right: inherit;
                            bottom: 90px;
                            left: 130px;
                            z-index: 99;
                            .planet-with-action .round-won{
                                background-image: url(../round/map/rounds/round5-won.svg);
                            }
                        }

                        &.sat-planet-halo {
                            display: none;
                        }
                    }
                }
            }
            .podium-bg{
                .moving-bg.back{
                    &::after{
                        background-image: url(../podium/bg/cloud_4.svg);
                        background-position: top center;
                    }
                    &::before{
                        background-image: url(../podium/bg/cloud_3.svg);
                        background-position: top center;
                    }
                }
                .moving-bg{
                    &::before{
                        background-image: url(../podium/bg/cloud_2.svg);
                        background-position: top center;
                    }
                    &::after{
                        background-image: url(../podium/bg/cloud_1.svg);
                        background-position: top center;
                    }
                }
            }
            .game-view-position-sec{
                top: initial;
                height: 100%;
                .game-position-view{
                    height: 310px;
                    overflow: visible;
                    background-image: url(../podium/ground.svg);
                    background-size: 100%;
                    background-position: top center;
                    margin-top: calc(100vh - 370px);
                    z-index: 99;
                    transform: scaleY(.8);
                    transform-origin: bottom;
                    .game-player-position {
                        width: 780px;
                        height: 500px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        position: relative;
                        top: -240px;
                        box-shadow: inset 0 0 0 20px rgba(255, 255, 0, 0.2);
                        transform: scaleX(.8);
                        transform-origin: center;
                        @media screen and (max-width: 1399.98px){
                            transform: scale(1);
                            transform-origin: left;
                        }
                        .rocket {
                            position: absolute;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            .star-rating, .wp-border-section {
                                display: none !important;
                            }
                            &.green, &.red{
                                margin-top: 0rem;
                            }
                            .rocket-smoke{
                                height: auto;
                                background: transparent !important;
                                width: 0%;
                                img{
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                    object-position: left;
                                }
                            }
                            &.green{
                                z-index: 8;
                                top: 8.5em;
                                left: -21em;
                                width: 170%;
                                .rocket-smoke {
                                    animation: 1s podium-healthdash-2 2s ease forwards;
                                }
                                .whole-winner-frames{
                                    .team-position{
                                        transform: scale(0.9) rotate(0deg);
                                        bottom: 190px;
                                        left: -300px;
                                        opacity: 0;
                                        transform: translateY(-30px);
                                        animation: 1s textShow 6s forwards;
                                        width: 160px;
                                        img{
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                        svg{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &.red{
                                z-index: 7;
                                top: 6em;
                                left: -8em;
                                width: 150%;
                                .rocket-smoke {
                                    animation: 1s podium-healthdash-3 1s ease forwards;
                                }
                                .whole-winner-frames{
                                    .team-position{
                                        transform: scale(0.8) rotate(0deg);
                                        bottom: 160px;
                                        left: -242px;
                                        opacity: 0;
                                        transform: translateY(-30px);
                                        animation: 1s textShow 5s forwards;
                                        img{
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                        svg{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &.blue{
                                z-index: 9;
                                left: -32em;
                                width: 140%;
                                top: 11em;
                                .rocket-smoke {
                                    animation: 1s podium-healthdash-1 3s ease forwards;
                                }
                                .whole-winner-frames{
                                    .team-position {
                                        transform: rotate(0deg);
                                        bottom: 230px;
                                        left: -360px;
                                        opacity: 0;
                                        transform: translateY(-30px);
                                        animation: 1s textShow 7s forwards;
                                        width: 170px;
                                        img{
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                        svg{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // .team-position{
                    //     display: none;
                    // }
                    .gp-bg{
                        transform: rotate(0deg);
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        .gp-img-section{
                            display: none;
                        }
                        .winning-positions {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &.podium-winners{
                        @media screen and (max-height:900px) {
                            transform: scaleY(.6);
                            transform-origin: bottom;
                            .game-player-position{
                                transform: scaleX(.6);
                                transform-origin: center;
                            }
                        }
                    }
                    &.winners-section{
                        @media screen and (max-height:900px) {
                            transform: scaleY(.5);
                            transform-origin: bottom;
                            .game-player-position{
                                transform: scaleX(.5);
                                transform-origin: center;
                            }
                        }
                    }
                }
            }
        }

        .header-bg {
            z-index: 999;
        }
    }

    










//// NEO PODIUM
    // #sun-rays,
    // #podium-visual {
    //     display: none;
    // }

    .presentation-start-view .main-content .game-view-position-sec .game-position-view .game-player-position {
        /// HIDE GUIDE BOX
        box-shadow:none;
    }
    .presentation-start-view .main-content .game-view-position-sec .game-position-view .game-player-position .rocket{
        .rocket-smoke img {
            height: 12000px;
            margin: 0;
        }
        &.blue {
            top:unset;
            bottom: -12em;
            width: 75em;
            left: -44em;
            .whole-winner-frames .team-position {
                left: -2em;
                animation: textShow 2s 3.4s forwards;
            }
            .rocket-smoke {
                animation: podium-healthdash-1 3s ease 0.7s forwards;
            }
        }
        &.green {
            top:unset;
            bottom: -12em;
            width: 75em;
            left: -21em;
            .whole-winner-frames .team-position {
                left: -2em;
                animation: textShow 2s 3.1s forwards;
            }
            .rocket-smoke {
                animation: podium-healthdash-2 3s ease 0.4s forwards;
            }
        }
        &.red {
            top:unset;
            bottom: -12em;
            width: 75em;
            left: -5em;
            .whole-winner-frames .team-position {
                left:-10em;
                animation: textShow 2s 2.7s forwards;
            }
            .rocket-smoke {
                animation: podium-healthdash-3 3s ease 0s forwards;
            }
        }
    }

    @keyframes podium-healthdash-1 {
    0% {
        width: 0%;
        filter: brightness(.8);
        transform: translateY(400px);
    }
    90% {
        width: 85%;
        filter: brightness(.8);
    }
    100%{
        width: 85%;
        filter: brightness(1);
    }
    }
    @keyframes podium-healthdash-2 {
        0% {
            width: 0%;
            filter: brightness(.8);
            transform: translateY(300px);
        }
        90% {
            width: 74%;
            filter: brightness(.8);
        }
        100%{
            
            width: 74%;
            filter: brightness(1);
        }
    }
    @keyframes podium-healthdash-3 {
        0% {
            width: 0%;
            filter: brightness(.8);
            transform: translateY(300px);
        }
        90% {
            width: 82%;
            filter: brightness(.8);
        }
        100%{
            
            width: 82%;
            filter: brightness(1);
        }
    }



    @keyframes textShow {
        0% {
            opacity: 0;
            transform: translateY(30px);
            filter: brightness(.85);
        }
        80% {
            filter: brightness(.85);
        }
        90% {
            transform: translateY(0px);
            filter: brightness(1);
        }
        100% {
            opacity: 1;
            transform: translateY(2px);
            filter: brightness(.85);
        }
    }

    


    @for $i from 1 through 6 {
        .sat-orbit-line .soh-mask:nth-child(n + #{$i}) {
            z-index: (calc(99 - ($i + 1)));
        }
    }


    .presentation-controller-main {
        h2 {
            font-family: $sub-font;
        }

        .custom-light-navy-bg {
            background-color: transparent;

            * {
                font-family: "RacingSans";
            }

            h5 {
                font-size: 40px;
            }

            h1 {
                font-size: 60px;
            }
        }
    }

    &:not(.player-skin) .leader-board-section {
        position: absolute;
        top: 50px;
        height: calc(50vh - 50px - 90px - 50px);
        // background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        & > * {
            width: 100%;
        }
    }
    &:not(.player-skin) .custom-podium-table .leader-board-section {
        flex-direction: column;
    }
    .leader-board-section {
        z-index: 999;
        // @media screen and (min-height: 1000px) {
        //     top: 5vh;
        // }
        .leader-board-table-header {
            h6 {
                background-color: #AA6600;
                padding: 3px 5px;
                border-radius: 0px;
                font-family: "RacingSans";
                font-size: 24px;
                margin-bottom: 2px;
                position: relative;
                line-height: 1;

                &::before,
                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 17px solid transparent;
                    border-right: 0px solid transparent;
                    border-bottom: 36px solid #AA6600;
                    position: absolute;
                    left: -17px;
                    top: 0;

                    @media screen and (max-width : 1200px) {
                        border-bottom: 30px solid #AA6600;
                        left: -16px;
                    }
                }

                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 17px solid transparent;
                    border-right: 0px solid transparent;
                    position: absolute;
                    right: -16px;
                    top: 0;
                    left: initial;
                    transform: rotate(180deg);
                }

                @media screen and (min-width : 1200px) {
                    border-radius: 0;
                    font-size: 30px;
                }
            }

            p {
                margin-bottom: 4px;
                text-shadow: 0px 0px 0.5px #000000, -0.5px -0.5px 0px #000000;
            }
        }


        .leader-board-table-row {
            border-radius: 0px;
            transform: skewX(-20deg) !important;
            margin: 0 !important;
            font-size: 24px;
            font-family: 'Space Mono', monospace;
            line-height: 1.1;
            p,
            .teb-icon,
            .tr-count {
                transform: skewX(20deg);
            }

            p {
                margin-top: 0 !important;
                color: #211D19;
                text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75), 1px 1px 0px #FFFFFF;
                font-style: normal;
                font-weight: initial;
            }

            .player-point-adjust {
                span:last-child {
                    border-radius: 0;
                }
            }

            .tr-count {
                font-size: 32px;
                font-family: "RacingSans";
                text-shadow: 0px 0px 1px #000000, 0px 0px 8px #FFFFFF, -1px -1px 0px #000000;
                background: transparent !important;
            }
        }
        //  VERTICAL TABLE
        &.verticle-table{
            .leader-board-table-row {
                transform: skewX(-20deg) rotate(-20deg) !important;
                & > * > * > *:nth-child(2) {
                    background-color: black;
                    background: linear-gradient(0deg, rgba(0,0,0,0) 00%, rgba(0,0,0,1) calc(100% - 30px));
                }
                p,
                .team-icon,
                .tr-count {
                    transform: skewX(0deg);
                    transform: skewX(20deg) rotate(20deg);
                    // transform: none;
                }
                .total-font {
                    margin-left: -0.25em;
                }
            }
        }


        ul.leaderboard-ul-list {
            .first-list-sec {
                .list-item {
                    &:first-of-type {
                        border-radius: 0;
                        margin-right: 0;
                        z-index: 9;

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            .player-point-round {
                span:last-child {
                    p {
                        color: #211D19 !important;
                    }
                }
            }

            .list-item {
                padding-top: 1px;
                padding-bottom: 1px;
                border-radius: 0 !important;
                &::before {
                    display: none;
                }

                &::after {
                    height: 2px;
                }

                &:last-of-type {
                    &::after {
                        width: 100%;
                    }
                }
                .total-sec{
                    color: #211D19 !important;
                }
                &.podium-total{
                    color: #211D19 !important;
                }
            }
        }
    }

    .rocket-svg {
        // :nth-child(1) {
        //     display: none;
        // }

        img {
            // content: url(../assets/car-element.gif);
            height: 42px;
            width: auto;
        }
    }

    $tweener: 1.5s;
    // $offset: 0.225;
    $offset: .065;
    $columnDelay: .5;
    $trans-color: rgba(14, 18, 49, 0.4);

    $teamCount: 10;

    @for $itemIndex from 1 through 10 {
        @for $teamIndex from 1 through $teamCount {
            #team#{$teamIndex} .item#{$itemIndex} {
                animation-delay: #{ ( $tweener / 2 ) * $offset * ( (( $itemIndex - 1 ) * ( $teamCount + $columnDelay )) + ($teamIndex*2) ) };

                @if $itemIndex ==2 {
                    background: rgb(var(--question-leaderboard-color-#{$teamIndex}));
                }

                @else if $itemIndex ==6 {
                    background: rgb(var(--question-leaderboard-color-#{$teamIndex}));
                }

                @else {
                    background: $trans-color;

                    p {
                        color: rgb(var(--question-leaderboard-color-#{$teamIndex}));
                        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75), 1px 1px 0px #FFFFFF;
                    }
                }
            }
        }
    }
    .team{
        .list-item:last-child{
            background-color: var(--element-color) !important;
        }
    }

    .orbit-path-bg {
        display: none;
    }


    #macadam {
        fill: none;
        stroke: url(#macadam);
        stroke-width: 30;
        stroke-linecap: round;
    }

    #stripes {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-dasharray: 10, 10;
    }

    /*
        STARTING STATES
    */
    #round1,
    #round2,
    #round3,
    #round4,
    #round5 {
        fill: none;
        stroke-width: 30;
        stroke-dasharray: 50%, 50%;
        stroke-dashoffset: 0%;
        stroke-linecap: square;
    }

    #round1 {
        // stroke: #85C534;
        transform: translate(-5px, -5px);
    }

    #round2 {
        
        transform: translate(-5px, -5px);
    }

    #round3 {

        transform: translate(18px, 15px);
    }

    #round4 {
        transform: translate(18px, 15px);
    }

    #round5 {

        transform: translate(15px, 15px);
    }

    /*
        ANIMATIONTS
    */
    @keyframes grow {
        from {
            stroke-dashoffset: 50%;
        }

        to {
            stroke-dashoffset: 0%;
        }
    }

    /* We might need to have an inverse animation depending on how the svg path is made */
    @keyframes grow-inverse {
        from {
            stroke-dashoffset: 50%;
        }

        to {
            stroke-dashoffset: 100%;
        }
    }

    @keyframes grow-inverse-1 {
        from {
            stroke-dashoffset: 60%;
        }

        to {
            stroke-dashoffset: 110%;
        }
    }


    @keyframes roundShow {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }


    /* forwards maintains the final state of the animation */
    #round1 {
        stroke-dashoffset: 50%;
        animation: grow 1.5s linear 3s forwards;
    }

    #round2 {
        stroke-dashoffset: 50%;
        stroke-dasharray: 50%, 51%;
        animation: grow-inverse 2s linear 4s forwards;

    }

    #round3 {
        stroke-dashoffset: 50%;
        animation: grow 2s linear 6s forwards;

    }

    #round4 {
        stroke-dashoffset: 52%;
        stroke-dasharray: 52%, 60%;
        animation: grow 2s linear 7.8s forwards;

    }

    #round5 {
        stroke-dashoffset: 53%;
        stroke-dasharray: 55%, 63%;
        animation: grow-inverse-1 1.7s linear 9.8s forwards;

    }

    // TEAM COLOURS FOR ROUNDS AND MORE
    .round-won {
        filter: invert(100%) sepia(0%) saturate(2401%) hue-rotate(249deg) brightness(121%) contrast(100%);
    }
    .theme_0 .team1,
    .color_C53434,
    .team_C53434 {
        /* C53434; */
        filter: invert(29%) sepia(37%) saturate(3095%) hue-rotate(339deg) brightness(93%) contrast(86%);
    }

    .theme_0 .team2,
    .team_C59C34,
    .color_C59C34 {
        /* C59C34 */
        filter: invert(82%) sepia(72%) saturate(2150%) hue-rotate(328deg) brightness(81%) contrast(87%);
    }

    .theme_0 .team3,
    .team_85C534,
    .color_85C534 {
        /* 85C534 */
        filter: invert(61%) sepia(64%) saturate(461%) hue-rotate(44deg) brightness(101%) contrast(89%);
    }
    .theme_0 .team4,
    .team_34C5A2,
    .color_34C5A2 {
        /* 34C5A2; */
        filter: invert(64%) sepia(22%) saturate(1147%) hue-rotate(115deg) brightness(99%) contrast(87%);
    }

    .theme_0 .team5,
    .team_346EC5,
    .color_346EC5 {
        /* 346EC5 */
        filter: invert(35%) sepia(86%) saturate(644%) hue-rotate(182deg) brightness(93%) contrast(90%);
    }

    .theme_0 .team6,
    .team_6234C5,
    .color_6234C5 {
        /* 6234C5 */
        filter: invert(25%) sepia(76%) saturate(2972%) hue-rotate(249deg) brightness(77%) contrast(100%);
    }
    .theme_0 .team7,
    .team_C534A5,
    .color_C534A5 {
        /* C534A5 */
        filter: invert(46%) sepia(50%) saturate(6385%) hue-rotate(288deg) brightness(79%) contrast(93%);
    }
    .theme_0 .team8,
    .team_C5344E,
    .color_C5344E {
        /* C5344E */
        filter: invert(32%) sepia(41%) saturate(7495%) hue-rotate(334deg) brightness(84%) contrast(81%);
    }

    .theme_1 .team1,
    .team_B84F3E,
    .color_B84F3E {
        /* B84F3E; */
        filter: invert(35%) sepia(29%) saturate(1683%) hue-rotate(325deg) brightness(96%) contrast(84%);
    }

    .theme_1 .team2,
    .team_B99149,
    .color_B99149 {
        /* B99149 */
        filter: invert(66%) sepia(13%) saturate(1784%) hue-rotate(360deg) brightness(89%) contrast(76%);
    }

    .theme_1 .team3,
    .team_A4AD42,
    .color_A4AD42 {
        /* A4AD42 */
        filter: invert(59%) sepia(58%) saturate(399%) hue-rotate(26deg) brightness(99%) contrast(91%);
    }

    .theme_1 .team4,
    .team_3FC26F,
    .color_3FC26F {
        /* 3FC26F; */
        filter: invert(81%) sepia(42%) saturate(790%) hue-rotate(76deg) brightness(84%) contrast(76%);
    }

    .theme_1 .team5,
    .team_408BD1,
    .color_408BD1 {
        /* 408BD1 */
        filter: invert(57%) sepia(10%) saturate(2364%) hue-rotate(168deg) brightness(85%) contrast(111%);
    }

    .theme_1 .team6,
    .team_5F4CA3,
    .color_5F4CA3 {
        /* 5F4CA3 */
        filter: invert(27%) sepia(42%) saturate(1998%) hue-rotate(228deg) brightness(92%) contrast(77%);
    }

    .theme_1 .team7,
    .team_AA4395,
    .color_AA4395 {
        /* AA4395 */
        filter: invert(43%) sepia(14%) saturate(2374%) hue-rotate(260deg) brightness(84%) contrast(98%);
    }

    .theme_1 .team8,
    .team_C5344E,
    .color_C5344E {
        /* C5344E */
        filter: invert(40%) sepia(49%) saturate(1378%) hue-rotate(311deg) brightness(79%) contrast(90%);
    }
}


@keyframes orbitanimation1 {
    from {
        stroke-dasharray: 0 5000;
    }

    to {
        // stroke-dasharray: 500 5000;
        stroke-dasharray: 100 5000;
    }
}

@keyframes orbitanimation2 {
    from {
        stroke-dasharray: 0 5000;
    }

    to {
        // stroke-dasharray: 800 5000;
        stroke-dasharray: 100 5000;
    }
}

@keyframes orbitanimation3 {
    from {
        stroke-dasharray: 0 5000;
    }

    to {
        // stroke-dasharray: 1200 5000;
        stroke-dasharray: 100 5000;
    }
}

@keyframes orbitanimation4 {
    from {
        stroke-dasharray: 0 5000;
    }

    to {
        stroke-dasharray: 1800 5000;
    }
}

@keyframes orbitanimation5 {
    from {
        stroke-dasharray: 0 5000;
    }

    to {
        stroke-dasharray: 2500 5000;
    }
}
.skin-healthdash{
    .footer-bg{
        display: none;
    }
}

.skin-healthdash{
    #cloud1,
    #cloud1:after,
    #cloud2,
    #cloud2:after,
    #cloud3,
    #cloud3:after,
    #cloud4,
    #cloud4:after,
    #laurel-nr_1,
    #laurel-nr_2,
    #laurel-nr_3,
    #nr_1,
    #nr_2,
    #nr_3,
    #small-cloud1,
    #small-cloud1:after,
    #small-cloud2,
    #small-cloud2:after,
    #small-cloud3,
    #small-cloud3:after,
    #sun,
    .bgImgContain,
    .slide-start:after {
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    #ambient-occlusion,
    #ground,
    #sky,
    .bgImgCover,
    .full-screen-img {
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #cloud1:after,
    #cloud2:after,
    #cloud3:after,
    #cloud4:after,
    #small-cloud1:after,
    #small-cloud2:after,
    #small-cloud3:after,
    .pseudo,
    .slide-start:after {
        content: "";
        display: block;
        position: absolute;
    }
    #ambient-occlusion,
    #podium-visual,
    #sky,
    .absoluteFullVieport,
    .full-screen-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #leadeboard {
        z-index: 10;
        position: relative;
        background-color: rgba(50, 0, 50, 0.4);
        width: 834px;
        height: 306px;
        margin: 46px auto 0;
        text-align: center;
        padding: 2em;
    }
    #podium-visual {
        overflow: hidden;
    }
    #background,
    #foreground,
    #medals,
    #teams,
    .graphic-box {
        position: absolute;
        width: 834em;
        height: 540em;
        top: calc(352px + ((100vh - 352px - 540em) / 2));
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    #ambient-occlusion,
    #sky,
    .full-screen-img {
        position: fixed;
    }
    #sky {
        // background-image: url(../img/podium/bg/sky.svg);
        background-image: url(../podium/bg/sky.svg);
    }
    #sun {
        z-index: 1;
        width: 474em;
        height: 474em;
        left: 0;
        right: 0;
        top: -325em;
        // background-image: url(../img/podium/bg/sun.svg);
        background-image: url(../podium/bg/sun.svg);
    }
    #cloud4,
    #sun {
        position: absolute;
        margin: 0 auto;
    }
    #cloud4 {
        z-index: 2;
        width: 5015em;
        height: 420.64em;
        left: -986em;
        top: -95em;
        // background-image: url(../img/podium/bg/cloud_4.svg);
        background-image: url(../podium/bg/cloud_4.svg);
    }
    #cloud3 {
        z-index: 3;
        width: 4610em;
        height: 396.4em;
        left: -1028em;
        top: -70em;
        // background-image: url(../img/podium/bg/cloud_3.svg);
        background-image: url(../podium/bg/cloud_3.svg);
    }
    #cloud2,
    #cloud3 {
        position: absolute;
        margin: 0 auto;
    }
    #cloud2 {
        z-index: 4;
        width: 4790em;
        height: 307.22em;
        left: -867em;
        top: 19em;
        // background-image: url(../img/podium/bg/cloud_2.svg);
        background-image: url(../podium/bg/cloud_2.svg);
    }
    #cloud1 {
        z-index: 5;
        width: 4908em;
        height: 223em;
        margin: 0 auto;
        left: -995em;
        top: 103em;
        // background-image: url(../img/podium/bg/cloud_1.svg);
        background-image: url(../podium/bg/cloud_1.svg);
    }
    #cloud1,
    #ground {
        position: absolute;
    }
    #teams {
        z-index: -9;
        box-shadow: inset 0 0 0 20px rgba(255, 255, 0, 0.2);
    }
    #nr_3 {
        z-index: 1;
        position: absolute;
        width: 1058em;
        height: 245.74em;
        left: -81.49em;
        top: 206.42em;
    }
    #static-teams #nr_3 {
        // background-image: url(../img/podium/teams/clr_0/nr_3-C534A5.svg);
        background-image: url(../podium/teams/nr_3.svg);
    }
    #nr_2 {
        z-index: 2;
        position: absolute;
        width: 1135em;
        height: 314.37em;
        left: -375.49em;
        top: 213.1em;
    }
    #static-teams #nr_2 {
        // background-image: url(../img/podium/teams/clr_0/nr_2-C53434.svg);
        background-image: url(../podium/teams/nr_2.svg);
    }
    #nr_1 {
        z-index: 3;
        position: absolute;
        width: 1080em;
        height: 420.51em;
        left: -587.49em;
        top: 206.42em;
    }
    #static-teams #nr_1 {
        // background-image: url(../img/podium/teams/clr_0/nr_1-85C534.svg);
        background-image: url(../podium/teams/nr_1.svg);
    }
    // #foreground {
    //     z-index: -8;
    // }
    // #sun-rays {
    //     z-index: 1;
    //     width: 100vw;
    //     height: 100vh;
    //     left: 0;
    //     top: 0;
    //     background-image: url(../podium/fg/sun_rays.svg);
    // }
    #sun-rays {
        z-index: 1;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-image: url(../podium/fg/sun_rays.svg);
        position: fixed !important;
        background-size: contain;
        background-position: center;
        background-position-y: 60px;
        opacity: .2;
        z-index: 999;
    }
    #small-cloud3,
    #sun-rays {
        position: absolute;
        margin: 0 auto;
    }
    #small-cloud3 {
        z-index: 2;
        width: 869em;
        height: 152.24em;
        left: 599em;
        top: -352em;
        // background-image: url(../img/podium/fg/small_cloud_3.svg);
        background-image: url(../podium/fg/small_cloud_3.svg);
    }
    #small-cloud2 {
        z-index: 3;
        width: 904em;
        height: 121.33em;
        left: -586em;
        top: -316em;
        // background-image: url(../img/podium/fg/small_cloud_2.svg);
        background-image: url(../podium/fg/small_cloud_2.svg);
    }
    #small-cloud1,
    #small-cloud2 {
        position: absolute;
        margin: 0 auto;
    }
    #small-cloud1 {
        z-index: 4;
        width: 746em;
        height: 109.52em;
        left: 857em;
        top: -304em;
        // background-image: url(../img/podium/fg/small_cloud_1.svg);
        background-image: url(../podium/fg/small_cloud_1.svg);
    }
    #ambient-occlusion {
        z-index: 3;
        // background-image: url(../img/podium/fg/ambient_occlusion.svg);
        background-image: url(../podium/fg/ambient_occlusion.svg);
    }
    #medals {
        z-index: -7;
    }
    #laurel-nr_3 {
        z-index: 1;
        width: 138em;
        height: 121.8em;
        left: 662em;
        top: 72em;
        // background-image: url(../img/podium/laurel_nr_3.svg);
        background-image: url(../podium/laurel_nr_3.svg);
    }
    #laurel-nr_2,
    #laurel-nr_3 {
        position: absolute;
        margin: 0 auto;
    }
    #laurel-nr_2 {
        z-index: 2;
        width: 179em;
        height: 157.36em;
        left: 377em;
        top: 44em;
        // background-image: url(../img/podium/laurel_nr_2.svg);
        background-image: url(../podium/laurel_nr_2.svg);
    }
    #laurel-nr_1 {
        z-index: 3;
        position: absolute;
        width: 233em;
        height: 204.91em;
        left: 34em;
        top: 8em;
        margin: 0 auto;
        // background-image: url(../img/podium/laurel_nr_1.svg);
        background-image: url(../podium/laurel_nr_1.svg);
    }
    #animate-btn {
        z-index: 20;
        position: fixed;
        top: 200px;
        right: 0;
        left: 0;
        height: 2em;
        width: 8em;
        margin: 0 auto;
    }
    .hide {
        display: none;
    }
    #cloud1,
    #cloud2,
    #cloud3,
    #cloud4,
    #small-cloud1,
    #small-cloud2,
    #small-cloud3,
    .slide-start {
        transform: scaleX(1);
        translate: 0 0;
    }
    #cloud1:after,
    #cloud2:after,
    #cloud3:after,
    #cloud4:after,
    #small-cloud1:after,
    #small-cloud2:after,
    #small-cloud3:after,
    .slide-start:after {
        width: 100%;
        height: 100%;
        top: 0;
    }
    @keyframes a {
        0% {
            translate: 0;
        }
        to {
            translate: -100%;
        }
    }
    #cloud4 {
        animation: 140s linear 0s infinite running a;
    }
    #cloud4:after {
        right: -100%;
        // background-image: url(../img/podium/bg/cloud_4.svg);
        background-image: url(../podium/bg/cloud_4.svg);
    }
    #cloud3 {
        animation: 105s linear 0s infinite running a;
    }
    #cloud3:after {
        right: -100%;
        // background-image: url(../img/podium/bg/cloud_3.svg);
        background-image: url(../podium/bg/cloud_3.svg);
    }
    #cloud2 {
        animation: 80s linear 0s infinite running a;
    }
    #cloud2:after {
        right: -100%;
        // background-image: url(../img/podium/bg/cloud_2.svg);
        background-image: url(../podium/bg/cloud_2.svg);
    }
    #cloud1 {
        animation: 60s linear 0s infinite running a;
    }
    #cloud1:after {
        right: -100%;
        // background-image: url(../img/podium/bg/cloud_1.svg);
        background-image: url(../podium/bg/cloud_1.svg);
    }
    @keyframes b {
        0% {
            translate: 0;
        }
        to {
            translate: -400%;
        }
    }
    #small-cloud3 {
        animation: 55s linear 0s infinite running b;
    }
    #small-cloud3:after {
        right: -400%;
        // background-image: url(../img/podium/fg/small_cloud_3.svg);
        background-image: url(../podium/fg/small_cloud_3.svg);
    }
    #small-cloud2 {
        animation: 35s linear 0s infinite running b;
    }
    #small-cloud2:after {
        right: -400%;
        // background-image: url(../img/podium/fg/small_cloud_2.svg);
        background-image: url(../podium/fg/small_cloud_2.svg);
    }
    #small-cloud1 {
        animation: 25s linear 0s infinite running b;
    }
    #small-cloud1:after {
        right: -400%;
        // background-image: url(../img/podium/fg/small_cloud_1.svg);
        background-image: url(../podium/fg/small_cloud_1.svg);
    }
    #laurel-nr_1,
    #laurel-nr_2,
    #laurel-nr_3,
    .medals-hide {
        transform: scale3d(0, 0, 0);
        translate: 0 50em;
        transform-origin: center bottom;
        opacity: 1;
    }
    #nr_1,
    #nr_2,
    #nr_3,
    .teams-hide {
        transform: scale3d(0, 0, 1);
        opacity: 0;
    }
    // #sun-rays {
    //     transform-origin: 50% 25%;
    //     z-index: 999;
    // }
    #sun{
        translate: 0 300em;
        transform: scale3d(1.1, 1.1, 1) translateY(5em);
        opacity: 0;
        filter: brightness(1);
    }
    @keyframes c {
        0% {
            transform: scaleX(1);
            translate: 0 50em;
            opacity: 0;
        }
        70% {
            filter: brightness(1);
        }
        90% {
            filter: brightness(1.2);
            translate: 0 -2em;
        }
        to {
            transform: scaleX(1);
            transform: scale(1);
            translate: 0 0;
            opacity: 1;
        }
    }
    @keyframes d {
        0% {
            transform: scale3d(0, 0, 1);
            filter: brightness(1) sepia(1);
        }
        5% {
            opacity: 1;
        }
        90% {
            transform: scale3d(1.01, 1.01, 1.01);
            filter: brightness(0.8) sepia(0.1);
        }
        to {
            opacity: 1;
            transform: scaleX(1);
            filter: brightness(1) sepia(0);
        }
    }
    @keyframes e {
        0% {
            translate: 0 300em;
            transform: scale3d(1.1, 1.1, 1) translateY(5em);
            opacity: 0;
            filter: brightness(1);
        }
        70% {
            filter: brightness(1);
        }
        90% {
            filter: brightness(1.6);
        }
        to {
            translate: 0 0;
            transform: scaleX(1) translateY(0em);
            opacity: 1;
            filter: brightness(1.3);
        }
    }
    @keyframes f {
        0% {
            rotate: 6deg;
        }
        to {
            rotate: -6deg;
        }
    }
    @keyframes g {
        0% {
            transform: scale3d(1.1, 1.1, 1);
            opacity: 0;
            filter: brightness(1);
        }
        70% {
            filter: brightness(1);
        }
        90% {
            filter: brightness(1.6);
        }
        to {
            transform: scaleX(1);
            opacity: .5;
            filter: brightness(1.3);
        }
    }
    #laurel-nr_1 {
        animation: c 1s ease-out 5.8s forwards;
    }
    #laurel-nr_2 {
        animation: c 1s ease-out 5s forwards;
    }
    #laurel-nr_3 {
        animation: c 1s ease-out 4.2s forwards;
    }
    #nr_1 {
        transform-origin: left 20%;
        animation: d 1s ease-out 2.6s forwards;
    }
    #nr_2 {
        transform-origin: left 10%;
        animation: d 1s ease-out 1.8s forwards;
    }
    #nr_3 {
        transform-origin: left 15%;
        animation: d 1s ease-out 1s forwards;
    }
    #sun {
        animation: e 10s ease-out 0s forwards, 6s linear 0s infinite alternate f;
    }
    #sun-rays {
        animation: g 10s ease-out 0s forwards, 6s linear 0s infinite alternate f;
    }
    #podium-visual {
        font-size: 1px;
    }
    @media screen and (max-width: 833px) {
        #podium-visual {
            font-size: 0.1199vw;
        }
    }
    @media screen and (min-width: 834px) and (min-aspect-ratio: 5/4) {
        #podium-visual {
            font-size: calc((100vh - 352px) / 600);
        }
    }
    .svg-img {
        width: 100%;
        height: 100%;
    }

}

