@import '../../index.scss';

.yakeety-header {
    position: fixed;
    top: 0;
    z-index: 9999;
    .navbar {
        background-color: $white;
        .text-section {
            color: $btn-color;
            font-size: 18px;
            span {
                color: $black;
            }
            h5 {
                max-width: 350px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: "Qualy";
                @media screen and (max-width:$md-screen) {
                    font-size: 13px;
                    max-width: 130px;
                }
            }
            p{
                font-family: "Qualy";
                @media screen and (max-width:$md-screen) {
                    font-size: 13px;
                }
            }
        }
        .logo-img-section{
            img{
                @media screen and (max-width:$md-screen) {
                    height: 26px;
                    width: auto;
                }
            }
        }
    }
    .color-scheme-section {
        .cs-color {
            width: 20%;
            height: 20px !important;
            &.green {
                background-color: $choose-green;
            }
            &.red {
                background-color: $choose-red;
            }
            &.violet {
                background-color: #6234C5;
            }
            &.cyan {
                background-color: $choose-light-green;
            }
            &.blue {
                background-color: $choose-blue;
            }
        }
    }
}

.header-bg {
    position: fixed;
    top: 76px;
    z-index: 9;
    .header-overlay-img {
        margin-top: -30px;
        position: relative;
        .ho-img {
            width: 100vw;
            height: 178.18px;
        }
        .ho-img-short {
            height: 178.18px;
            @media screen and (max-width: $sm-screen) {
                height: 98px;
            }
        }
        .ho-logo-section-header-logo {
            width: 150px;
            left: 20px;
            top: 50px;
            height: 57px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
            }
            @media screen and (max-width: $sm-screen) {
                width: 36px;
                left: 20px;
                top: 40px;
            }
            @media screen and (max-width: $xs-screen) {
                top: 30px;
            }
            // @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
            //     width: 85px;
            // }
            @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
                width: 65px;
            }
        }
        .ho-logo-section-team-logo {
            right: 20px;
            top: 50px;
            width: 150px;
            height: 57px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
            }
            @media screen and (max-width: $sm-screen) {
                right: 10px;
                top: 45px;
                width: 70px;
                opacity: 0;
            }
            @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape) { 
                right: 10px;
                top: 45px;
                width: 70px;
            }
        }
    }
}

.full-screen-game .header-bg {
    top: 10px;
}


.small-space {
    @media screen and (max-width : $md-screen) {
        padding: 0 7px;
        .mob-full-width {
            width: 100%;
        }
        .mob-right-align {
            p {
                text-align: right !important;
                display: block;
                width: 100% !important;
            }
        }
    }
}