@import '../index.scss';

.presentation-team-main {
    .main-content {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        background: linear-gradient(225deg, rgba(170, 102, 0, 0.8) 0%, rgba(127, 24, 194, 0.8) 100%);
        padding-top: 122px;
        z-index: 99;
        .team-tables {
            li {
                text-align: left;
                color: $white;
            }
            .team-list-head {
                color: $white;
                .tl-head-logo {
                    width: 50px;
                    height: 50px;
                    color: $white;
                    font-size: 28px;
                    border-radius: 12px;
                    &.green {
                        background-color: #85C534;
                    }
                    &.red {
                        background-color: #C53434;
                    }
                    &.blue {
                        background-color: #6234C5;
                    }
                    &.cyan {
                        background-color: #34C5A2;
                    }
                    &.lightblue {
                        background-color: #346EC5;
                    }
                    svg{
                        margin-right: 0 !important;
                    }
                }
                h4{
                    font-family: Fredoka One;
                }
            }
        }
        &.team-list-view{
            height: calc(100vh - 60px);
            overflow: auto;
            padding-bottom: 100px;
        }
    }
}