@import '../index.scss';

.main-content{
    padding-top: 50px;
    min-height: calc(100vh - 50px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pq-whole-section {
        width: 100%;
        .graph-section {
            .graph-bar-section {
                height: 42vh;
                @media screen and (max-height:800px) {
                    height: 35vh;
                }
                .graph-bar {
                    width: 120px;
                    box-shadow: 2px 2px 0px #18C27F, inset 0px 0px 0px 1px #D8CCC1;
                    border-radius: 12px;
                    animation-fill-mode: forwards !important;
                    // &.custom-width-correct-graphbar{
                    //    width: 120px; 
                    // }
                    // &.custom-width-wrong-graphbar{
                    //     width: 120px; 
                    //  }
                    @media screen and (max-width: $md-screen) {
                        width: 110px;
                    }
                }

            }

            .first-question {
                .graph-bar-section {
                    .graph-bar {
                        background-color: #4FBE3D;
                        height: 0%;
                    }
                }
            }

            .second-question {
                .graph-bar-section {
                    .graph-bar {
                        background-color: #3D80BD;
                        height: 0%;
                    }
                }
            }

            .third-question {
                .graph-bar-section {
                    .graph-bar {
                        background-color: #E95D6E;
                        height: 0%;
                    }
                }
            }

            .fourth-question {
                .graph-bar-section {
                    .graph-bar {
                        background-color: #A8F55B;
                        height: 0%;
                    }
                }
            }

            .answer-section {
                width: 150px;
                background: rgba(14, 18, 49, 0.5);
                border-radius: 12px;
                padding: 15px;
                font-family: $quest-font;
                text-align: left;
                font-size: 14px;
                // display: -webkit-box;
                // -webkit-line-clamp: 3;
                // -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: 75px;
                min-height: 80px;
                color: #fff !important;
                word-break: break-all;
                @media screen and (max-width: $md-screen) {
                    width: 110px;
                }
            }
        }
    }
    @media screen and (max-width: $lg-screen) {
        .graph-section {
            transform: scale(.9);
        }
    }
    @media screen and (max-width: $md-screen) {
        .graph-section {
            transform: scale(.8);
        }
    }
}

.header-bg {
    .header-overlay-img {
        .ho-logo-section-team-logo {
            @media screen and (max-width: $md-screen) {
                width: 90px;
            }
        }
    }
}

.custom-margin-for-presentation-lb {
    .leader-board-section {
        @media screen and (max-width: $md-screen) {
            margin-left: 11% !important;
        }
    }
}



.graph-bar-section {
    // @for $i from 1 through 6 {
    //     .custom-podium-table .leader-board-table-row:nth-child(n + #{$i}) {
    //         transform: translateX(calc(-25px * ($i - 2)));
    //     }
    // }
  
    // div::after {
    //     font: 800 40px system-ui;
    //     content: counter(count);
    //     animation: counter 5s linear infinite alternate;
    //     counter-reset: count 0;
    //   }
      
      

.ps-trans-background {
    background: rgba(14, 18, 49, 0.4);
    border-radius: 12px;
    padding: 10px;
    // content: 'conternt';
    position: relative;
    font-family: Montserrat;
    // &:before {

    //     content: counter(count) '%';
    //     animation-name: counterAnim;
    //     animation-timing-function: linear;
    //     animation-fill-mode: forwards;
    //     counter-reset: count 0;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    // }
}


.graph-bar {

    // animation: graph-animation 3s ease;
    &.correct-ans {
        animation: graph-animation-2 2s ease;
    }
}
}

@keyframes graph-animation {
    0% {
        height: 0;
    }

    // 30% {
    //     height: 100%;
    // }
    100% {
        height: 100%;
    }
}

@keyframes graph-animation-2 {

    0% {
        width: 120px;
    }

    100% {
        width: 200px;
    }
}


@keyframes graph-animation-3 {

    0% {
        width: 120px;
    }

    100% {
        width: 50px;
    }
}

.answer-section.answer-section-correct-answer {
    width: 200px !important;
    max-height: initial !important;
    animation: fontanim 2s forwards;
}

 @keyframes fontanim  {
    0% {
       font-size:14px;
    }
    
    100% {
       font-size:24px;
    }
 
 }

 .ps-trans-background.answer-correct-percentage {
    width: 200px !important;
    max-height: initial !important;
    animation: percentage 2s forwards;
 }

 @keyframes percentage  {
    0% {
       font-size:1rem;
    }
    
    100% {
       font-size:1.5rem;
    }
 
 }

 .small-perc {
	font-size: 14px;
}