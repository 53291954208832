@import "../index.scss";

.player-questions {
  .header-bg {
    @media screen and (max-width: $sm-screen) {
      z-index: 0;
      // background-color: $black;
      height: 100px;
      .ho-logo-section-team-logo {
        display: none;
      }
      // .header-overlay-img .ho-logo-section-header-logo {
      //     width: 75px;
      // }
    }
  }
  .main-content {
    padding-top: 50px;
    color: $white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.leader-board-custom-align {
      min-height: calc(100vh - 50px);
      @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
          justify-content: flex-start !important;
          padding-top: 110px;
      }
      .round-logo{
        display: none;
      }
      .lite-bg{
        background: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.astro-lb {
          flex-direction: column;
        }
      }
    }
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        padding-bottom: 100px !important;
    }
    .pq-whole-section {
      width: 100%;
      padding: 5% 10%;
      font-family: $sub-font;
      @media screen and (max-width: $lg-screen) and (min-width: $md-screen) {
        padding-top: 6%;
      }
      @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
        padding-top: 14%;
      }
      @media screen and (max-width: $sm-screen) {
        padding: 20px;
      }
      .pq-head {
        font-size: 30px;
        width: 80%;
        margin: 0 auto;
        @media screen and (max-width: $sm-screen) {
            position: fixed;
            background-color: transparent;
            left: 0;
            right: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid #18C27F;
        }
        @media screen and (max-width: $sm-screen) {
          width: 90%;
        }
        p {
          font-size: 14px;
          text-align: left;
          font-family: $quest-font;
        }
        .time-progress {
          position: absolute;
          bottom: -40px;
          left: 0;
          width: 100%;
        }
      }
      .qstn-scroll-sec{
        @media screen and (min-width: $sm-screen) {
          margin-top: 20px !important;
          max-height: initial !important;
        }
      }
      .quest-img-loader-sec {
          @media screen and (max-width: $sm-screen) {
              padding-top: 85px;
          }
      }
      .qstn-scroll-sec{
        @media screen and (max-width: $sm-screen){
          max-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 10px;
          
        }
      }
      .quest-img {
        width: 40%;
        box-shadow: 2px 2px 0px #18c27f;
        border: 1px solid #d8ccc1;
        border-radius: 18px;
        overflow: hidden;
        height: 280px;
        background-color: #fff;
        &.no-img{
          background-color: transparent !important;
          border: none !important;
          box-shadow: none !important;
        }
        @media screen and (max-width: $lg-screen) and (min-width: $sm-screen) {
          width: 60%;
        }
        // @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
        //   width: 80%;
        // }
        @media screen and (max-width: $sm-screen) {
          width: 100%;
        }
        @media screen and (max-width: $xs-screen) {
          height: 200px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .answered-section {
        font-family: $quest-font;
        .answered-data {
          font-size: 32px;
          .line-seperate {
            height: 5px;
            background-color: $white;
            width: 60px;
          }
        }
      }
      .answer-option-section {
        font-family: $quest-font;
        font-size: 14px;
        .answer-option {
          box-shadow: 2px 2px 0px #18c27f, inset 0px 0px 0px 1px #d8ccc1;
          border-radius: 12px;
          padding-right: 10px;
          overflow: hidden;
          margin: 5px 0;
          .option-count {
            font-family: $sub-font;
            font-size: 40px;
          }
          .option-detail {
            padding: 12px;
            background-color: rgba($color: $white, $alpha: 0.7);
            color: $text-dark;
            min-height: 45px;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            word-break: break-word;
            &.pa-option-detail {
              background-color: rgba(14, 18, 49, 0.4);
              color: $white;
            }
          }
          &.first {
            background-color: #4fbe3d;
            &.selected{
              .option-detail{
                background-color: rgba(14, 18, 49, 0.4);
                color: #fff;
              }
            }
          }
          &.second {
            background-color: #3d80be;
            &.selected{
              .option-detail{
                background-color: rgba(14, 18, 49, 0.4);
                color: #fff;
              }
            }
          }
          &.third {
            background-color: #e95d6e;
            &.selected{
              .option-detail{
                background-color: rgba(14, 18, 49, 0.4);
                color: #fff;
              }
            }
          }
          &.fourth {
            background-color: #a8f55b;
            &.selected{
              .option-detail{
                background-color: rgba(14, 18, 49, 0.4);
                color: #fff;
              }
            }
          }
          &.selected {
            box-shadow: 0px 0px 0px 2px #0e1231,
              0px 0px 0px 10px rgba(176, 102, 226, 0.8);
          }
        }
        h6 {
          font-size: 20px;
          font-family: 'Fredoka One';
        }
        .answer-status-percentage {
          font-size: 24px;
        }
      }
    }
  }

  .ans-send-btn {
    color: $white;
    background: linear-gradient(225deg, #c53434 0%, #611a1a 100%);
    border-radius: 30px;
    width: 190px;
    height: 55px;
    font-size: 20px;
    z-index: 99;
    svg{
        position: relative;
        top: -2px;
    }
    &.ans-send-btn[disabled] {
      color: #fff !important;
    }
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }

  .team-info-footer {
    position: fixed;
    color: $white;
    right: 25px;
    bottom: 20px;
    z-index: 1;
    font-family: $sub-font;
    font-size: 18px;
    z-index: 99;
    @media screen and (max-width: $sm-screen) {
      top: 12px;
      bottom: unset;
      z-index: 999;
    }
    .player-start.team-icon-sec {
      border-radius: 12px;
    }
  }

  .ho-logo-section-team-logo {
    @media screen and (max-width: $sm-screen) {
      display: none;
    }
  }
}



.progress {
  background-color: transparent;
  overflow: visible;
  .progress-bar {
    box-shadow: 2px 2px 0px #18C27F;
  }
}