body {
    transition: all 0.50s linear;
    background-size: cover;
    overflow-x: hidden;
  }

  a {
  
    cursor: pointer;
  }

  /* button.btn {
    background-color: #ff0000;
  } */

  button.cancel {
    background-color: #ff0000;
  }

  button:disabled {
    background-color: #ebebeb;
    color: #333333 !important;
  }

  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #04080f;
    color: #FFFFFF;
  }

  .element{
    background-image:url("./assets/car.png");  
    background-size: contain;
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
  }

  .title::before{
    font-weight: bold;
    color: rgb(223, 102, 3);
    content: "Car Race";
  } 

  .background {
    background-color: #5a575754;
    text-align: center;
}