@import "../index.scss";

.player-questions {
  .header-bg {
    @media screen and (max-width: $sm-screen) {
      z-index: 0;
      // background-color: $black;
      height: 100px;
      .ho-logo-section-team-logo {
        display: none;
      }
      // .header-overlay-img .ho-logo-section-header-logo {
      //     width: 75px;
      // }
    }
  }
  .main-content {
    padding-top: 50px;
    color: $white;
    .pq-whole-section {
      padding: 3% 10%;
      font-family: $sub-font;
      @media screen and (max-width: $lg-screen) and (min-width: $md-screen) {
        padding-top: 6%;
      }
      @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
        padding-top: 14%;
      }
      @media screen and (max-width: $sm-screen) {
        padding-top: 20px;
      }
      .pq-head {
        font-size: 30px;
        width: 80%;
        margin: 0 auto;
        @media screen and (max-width: $sm-screen) {
            position: fixed;
            background-color: transparent;
            left: 0;
            right: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid #18C27F;
        }
        @media screen and (max-width: $xs-screen) {
          width: 90%;
        }
      }
      .quest-img-loader-sec {
          @media screen and (max-width: $sm-screen) {
              padding-top: 125px;
          }
      }
      .quest-img {
        width: 40%;
        box-shadow: 2px 2px 0px #18c27f;
        border: 1px solid #d8ccc1;
        border-radius: 18px;
        overflow: hidden;
        @media screen and (max-width: $lg-screen) and (min-width: $md-screen) {
          width: 60%;
        }
        @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
          width: 80%;
        }
        @media screen and (max-width: $sm-screen) {
          width: 100%;
        }
      }
      .answered-section {
        font-family: $quest-font;
        .answered-data {
          font-size: 32px;
          .line-seperate {
            height: 5px;
            background-color: $white;
            width: 60px;
          }
        }
      }
      .answer-option-section {
        font-family: $quest-font;
        font-size: 14px;
        .answer-option {
          box-shadow: 2px 2px 0px #18c27f, inset 0px 0px 0px 1px #d8ccc1;
          border-radius: 12px;
          padding-right: 20px;
          overflow: hidden;
          .option-count {
            font-family: $sub-font;
            // font-size: 20px;
          }
          .option-detail {
            padding: 12px;
            background-color: rgba($color: $white, $alpha: 0.7);
            color: $text-dark;
            min-height: 45px;
            &.pa-option-detail {
              background-color: rgba(14, 18, 49, 0.4);
              color: $white;
            }
          }
          &.first {
            background-color: #4fbe3d;
          }
          &.second {
            background-color: #3d80be;
          }
          &.third {
            background-color: #e95d6e;
          }
          &.fourth {
            background-color: #a8f55b;
          }
          &.selected {
            box-shadow: 0px 0px 0px 2px #0e1231,
              0px 0px 0px 10px rgba(176, 102, 226, 0.8);
          }
        }
      }
    }
  }

  .ans-send-btn {
    color: $white;
    background: linear-gradient(225deg, #c53434 0%, #611a1a 100%);
    border-radius: 30px;
    width: 220px;
    height: 50px;
    font-size: 20px;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }

  .team-info-footer {
    position: fixed;
    color: $white;
    right: 25px;
    bottom: 20px;
    z-index: 1;
    font-family: $sub-font;
    font-size: 18px;
    z-index: 99;
    @media screen and (max-width: $sm-screen) {
      top: 12px;
      bottom: unset;
    }
    .player-start.team-icon-sec {
      border-radius: 12px;
    }
  }

  .ho-logo-section-team-logo {
    @media screen and (max-width: $sm-screen) {
      display: none;
    }
  }
}
