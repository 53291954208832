@import '../index.scss';
@import './playerinvitation.scss';
.info-section {
    cursor: pointer;
}
.team-icon-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background-color: $choose-green;
    min-width: 40px;
    @media screen and (max-width:$xs-screen) {
        border-radius: 12px;
        border: 1px solid #fff; 
        min-width: 34px;
    }
    svg {
        fill: $white;
        margin: 0 !important;
        // path {
        //     fill: $white;
        // }
    }
    &.deactive {
        background-color: transparent;
        svg {
            fill: #346EC5;
            path {
                fill: #346EC5;
            }
        }
    }
    ~p{
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (max-width: $md-screen) {
            max-width: 200px;
        }
        @media screen and (max-width: 370px) {
            max-width: 150px;
        }
    }
}

.presentation-content {
    @media(max-width:$sm-screen) {
        max-width: 300px;
        margin-top: 45px;
        width: 100%;
    }
    @media screen and (max-width:$xs-screen) {
        margin-top: calc(100% - 150px);
    }
    @media (max-height:700px) and (max-width:$xs-screen) {
        margin-top: calc(100% - 210px);
    }
    h4 {
        color: $white;
        font-family: 'Fredoka One';
        font-size: 30px;
    }
    @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
        width: 80%;
        margin: 20px auto;
    }
}

.player-inv-head {
    h5, h1 {
        font-family: $sub-font;
    }
}

.team-player-count {
    color: #C53434;
}

.btn-close-custom {
    background: none;
}

.btn-close {
    position: absolute;
    right: 10px;
}

.signup-form{
    button.disabled{
        color: #EDE1F4;
        background-color: #fff;
        opacity: 1;
        border-color: #EDE1F4;
    }
    .player-name {
        font-family: "Fredoka One", cursive;
        font-size: 24px;
        margin-bottom: 20px;
    }
    label{
        // font-family: Comfortaa;
    }
}
.group-name{
    &.first-btn{
        div{
            margin-right: 15px;
        }
        button{
            pointer-events: none;
        }
    }
    .btn {
        display: flex;
        margin: 10px auto;
        background: #C53434;
        align-items: center;
        border-radius: 10px;
        min-width: 200px;
        justify-content: center;
        p{
            max-width: 125px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:focus{
            box-shadow: none !important;
            background: transparent;
        }
    }
    .btn-group-name-outline {
        color: #C53434;
        border-radius: 50px;
    }
}

button.start-btn{
    text-align: center;
    justify-content: center;
    &:focus{
        box-shadow: none ;
        border: 2px solid transparent;
        box-shadow: 2px 1000px 1px #ffffff inset !important;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(225deg, #aa6600 0%, #7f18c2 100%);
        color: #aa6600 !important;
    }
}

.show.dropdown .dropdown-menu {
	width: 100%;
    border: 1px solid #211D19;
    border-radius: 12px;
}

.defualt-right-cloud{
    @media screen and (max-width:767.98px) {
        display: none !important;
    }
}

.alert {
    &.alert-info {
        background-color: #00FECC;
    }
}

.btn-group-player {
    button {
        font-family: $quest-font;
        @media screen and (max-width: $sm-screen) {
            width: 100%;
        }
    }
}

.player-invitation {
    @media screen and (max-width: $xs-screen) {
        padding: 2% !important;
    }
    .main-content {
        @media screen and (max-width: $xs-screen) {
            width: 100%;
        }
    }
}

.pq-whole-section {
    &.player-result-view {
        padding: 3% !important;
        @media screen and (max-width: $sm-screen) {
            padding: 5% !important;
            padding-top: 4rem !important;
        }
        &.round-result-box {
            @media screen and (min-width: $sm-screen) {
                min-width: 640px;
            }
        }
        .answer-list {
            &--card {
                background: linear-gradient(225deg, rgba(54, 130, 43, 0.8) 0%, rgba(54, 130, 43, 0.4) 100%);
                border-radius: 24px;
                p {
                    font-family: $quest-font;
                    font-size: 18px;
                }
            }
            &--items {
                max-height: 300px;
                @media screen and (max-width: $xs-screen) {
                    max-height: 430px;
                }
                @media screen and (max-width: $xs-screen) {
                    max-height: 100%;
                }
                li {
                    list-style: none;
                    font-family: $quest-font;
                    font-size: 18px;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    // border-right: 1px solid $primary-light;
                    padding: 1rem 2rem;
                    // &:nth-child(9),&:nth-child(8),&:nth-child(7) {
                    //     border-right: 0;
                    // }
                    // @media screen and (max-width: $xs-screen) {
                    //     border: 0;
                    // }
                }
            }
        }
        .qstn-scroll-sec {
            max-width: 840px;
            width: 100%;
            margin: 0 auto;
            margin-top: 55px;
            @media screen and (max-width: $xs-screen) {
                max-height: calc(100vh - 200px) !important;
            }
        }
    }
}

.round-start {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    img {
        @media screen and (max-width: $xs-screen) {
            max-width: 80%;
            height: auto;
        }
    }
}

@for $players from 1 through 9 {
    @if $players>3 {
        .answer-list--items li:nth-child(n + #{$players}) {
            border-left: 1px solid $primary-light;
            @media screen and (max-width: $xs-screen) {
                border-left: 0 !important;
            }
        }
    }
}