@import '../index.scss';

@for $i from 1 through 6 {
    .custom-podium-table .leader-board-table-row:nth-child(n + #{$i}) {
        transform: translateX(calc(-25px * ($i - 2)));
    }
}

.custom-podium-table {
    position: relative;
    z-index: 999;
}

.leader-board-section {
    // margin-left: 20%;
}

.game-view-position-sec {
    left: 0;
    right: 0;
    top: 100px;
    bottom: 0;
    @media screen and (max-width:$md-screen) {
        
    }
}

.game-position-view {
    height: 90vh;
    overflow: hidden;
    .gp-bg {
        transform: rotate(-25deg);
        top: 0;
        left: -264px;
        z-index: 99;
        .gp-img-section {
            width: 50%;
            @media screen and (max-width: $md-screen) {
                width: 100%;
            }
        }
        // @media screen and (max-width: $xl-screen) {
        //     .winning-positions {
        //         transform: scale(.8);
        //         transform-origin: right;
        //     }
        // }
        @media screen and (max-width: $lg-screen) {
            top: 0px;
            left: -290px;
            // .winning-positions {
            //     transform: scale(0.8);
            //     transform-origin: bottom right;
            // }
        }
        @media screen and (max-width: $md-screen) {
            top: -140px;
            left: -155px;
            .winning-positions{
                transform: scale(0.8);
            }
            .gp-img-section{
                opacity: .2;
            }
        }
    }
    .game-player-position {
        .rocket {
            .rocket-smoke {
                height: 14px;
                text-align: right;
                img {
                    height: 40px;
                    margin-right: -80px;
                    margin-top: -20px;
                }
            }
            .rocket-svg {
                margin-left: -65px;
                width: 150px;
                z-index: 2;
                top: 90px;
                svg {
                    width: 100%;
                }
                &.rocket-second{
                    width: 130px;
                    margin-left: -50px;
                }
                &.rocket-third{
                    margin-left: -37px;
                    width: 110px;
                }
            }
            
            .star-rating {
                transform: rotate(25deg) translateX(-22px);
                z-index: 4;
                left: -10px;
                top: 30px;
                .small-star {
                    margin-top: 30px;
                }
                .big-star {
                    width: 70px;
                }
                div:first-child, div:last-child{
                    width: 48px !important;
                }
                &.second {
                    transform: rotate(25deg) translateX(7px);
                    top: 16px;
                    z-index: 4;
                    left: 0;
                    .small-star {
                        width: 48px;
                        img {
                            width: 100%;
                        }
                    }
                }
                &.third {
                    transform: rotate(25deg) translateX(27px);
                    top: 35px;
                    z-index: 4;
                    left: 0;
                    .big-star {
                        width: 60px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            .team-position {
                transform: rotate(25deg);
                bottom: 10px;
                left: -39px;
                img{
                    display: none;
                }
            }
            .wp-border-section {
                width: 250px;
                height: 250px;
                margin-left: -100px;
                .wp-border {
                    width: 150px;
                    height: 150px;
                    background-color: transparent;
                    border-radius: 50%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    &.round-border-1 {
                        border: 18px solid;
                    }
                    &.round-border-2 {
                        width: 180px;
                        height: 180px;
                        border: 25px solid;
                        opacity: 0.26;
                    }
                    &.round-border-3 {
                        width: 195px;
                        height: 195px;
                        border: 25px solid;
                        opacity: 0.08;
                    }
                    &.round-border-4 {
                        width: 210px;
                        height: 210px;
                        border: 35px solid;
                        opacity: 0.05;
                    }
                    &.round-border-5 {
                        width: 133px;
                        height: 133px;
                        border: 5px solid $white  !important;
                    }
                }
                &.second {
                    .wp-border {
                        width: 140px;
                        height: 140px;
                        background-color: transparent;
                        border-radius: 50%;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        &.round-border-1 {
                            border: 18px solid;
                        }
                        &.round-border-2 {
                            width: 170px;
                            height: 170px;
                            border: 15px solid;
                            opacity: 0.26;
                        }
                        &.round-border-3 {
                            width: 185px;
                            height: 185px;
                            border: 25px solid;
                            opacity: 0.08;
                        }
                        &.round-border-4 {
                            width: 200px;
                            height: 200px;
                            border: 40px solid;
                            opacity: 0.05;
                        }
                        &.round-border-5 {
                            width: 123px;
                            height: 123px;
                            border: 5px solid $white  !important;
                        }
                    }
                    ~.team-position{
                        transform: scale(.9) rotate(25deg);
                    }
                }
                &.third {
                    .wp-border {
                        width: 130px;
                        height: 130px;
                        background-color: transparent;
                        border-radius: 50%;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        &.round-border-1 {
                            border: 18px solid;
                        }
                        &.round-border-2 {
                            width: 160px;
                            height: 160px;
                            border: 15px solid;
                            opacity: 0.26;
                        }
                        &.round-border-3 {
                            width: 175px;
                            height: 175px;
                            border: 25px solid;
                            opacity: 0.08;
                        }
                        &.round-border-4 {
                            width: 190px;
                            height: 190px;
                            border: 40px solid;
                            opacity: 0.05;
                        }
                        &.round-border-5 {
                            width: 113px;
                            height: 113px;
                            border: 5px solid $white  !important;
                        }
                    }
                    ~.team-position{
                        transform: scale(.8) rotate(25deg); 
                        bottom: 22px;
                        left: -32px;
                    }
                }
            }
            &.blue {
                .rocket-smoke {
                    width: 85%;
                    background: linear-gradient(90deg, rgba(98, 52, 197, 0.5) 0.65%, #6234C5 3.9%, rgba(98, 52, 197, 0.25) 50.64%);
                    animation: podium-rocket-1 2s ease;
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #6234C5;
                        }
                    }
                }
                .team-position {
                    svg {
                        .team-position-color {
                            fill: #6234C5;
                        }
                    }
                }
                .wp-border-section {
                    .wp-border {
                        border-color: #6234C5;
                    }
                }
            }
            &.green {
                margin-top: -10rem;
                @media screen and (max-width: $md-screen) {
                    margin-top: -11rem;
                }
                .rocket-smoke {
                    width: 74%;
                    background: linear-gradient(90deg, rgba(133, 197, 52, 0.5) 0.65%, #85C534 3.9%, rgba(133, 197, 52, 0.25) 50.64%);
                    animation: podium-rocket-2 3s ease;
                    @media screen and (max-width: $lg-screen) {
                        width: 68%;
                        animation: podium-rocket-2-md 3s ease;
                    }
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #85C534;
                        }
                    }
                }
                .team-position {
                    svg {
                        .team-position-color {
                            fill: #85C534;
                        }
                    }
                }
                .wp-border-section.second {
                    .wp-border {
                        border-color: #85C534;
                    }
                }
            }
            &.red {
                margin-top: -6rem;
                @media screen and (max-width: $md-screen) {
                    margin-top: -8rem;
                }
                .rocket-smoke {
                    width: 82%;
                    background: linear-gradient(90deg, rgba(197, 52, 52, 0.5) 0.65%, #C53434 3.9%, rgba(197, 52, 52, 0.25) 50.64%);
                    animation: podium-rocket-3 4s ease;
                    @media screen and (max-width: $lg-screen) {
                        width: 78%;
                        animation: podium-rocket-3-md 3s ease;
                    }
                    img {
                        height: 35px;
                        margin-right: -72px;
                    }
                }
                .rocket-svg {
                    svg {
                        .color-overlay {
                            fill: #C53434;
                        }
                    }
                }
                .team-position {
                    svg {
                        .team-position-color {
                            fill: #C53434;
                        }
                    }
                }
                .wp-border-section.third {
                    .wp-border {
                        border-color: #C53434;
                    }
                }
            }
        }
    }
}

ul.leaderboard-ul-list {
    .list-item{
        &:first-of-type{
            &::before,&::after{
                width: 100% !important;
            }
        }
        &:last-of-type {
            &::before,&::after{
                width: calc(100% - 10px);
            }
        }
    }
    .first-list-sec{
        .list-item{
            &:first-of-type{
                padding: 2.5px 5px;
                border-radius: 60px 0 0 60px;
                margin-right: -20px;
                position: relative;
                z-index: 99;
                &::before,&::after{
                    display: none;
                }
            }
            &:last-of-type {
                &::before,&::after{
                    width: 100%;
                }
            }
        }
    }
}


.footer-bg{
    max-width: 700px;
    position: fixed;
    width: 100%;
    // bottom: 5px;
}

.whole-winner-frames {
    @media screen and (max-width: $md-screen) {
        transform: scale(0.85);
    }
}

@keyframes animateScroll {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(calc(-100% + 80vh));
    }
}
.intro-text {
    transform: translateY(0%);
    .it-content {
        font-family: 'Comfortaa';
        font-size: 25px;
        @media screen and (max-width:$sm-screen) {
            font-size: 22px;
        }
        @media screen and (max-width:$xs-screen) {
            font-size: 18px;
        }
    }
}


@keyframes podium-rocket-1 {
    from {
        width: 0%;
    }
    to {
        width: 85%;
    }
}

@keyframes podium-rocket-2 {
    from {
        width: 0%
    }
    to {
        width: 74%
    }
}

@keyframes podium-rocket-2-md {
    from {
        width: 0%
    }
    to {
        width: 68%
    }
}

@keyframes podium-rocket-3 {
    from {
        width: 0%
    }
    to {
        width: 82%
    }
}

@keyframes podium-rocket-3-md {
    from {
        width: 0%
    }
    to {
        width: 78%
    }
}

.presentation-start-view {
    overflow: hidden;
    .drush-head{
        display: none !important;
    }
}