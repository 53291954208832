@import "../index.scss";
@import "./playerquestions.scss";

.player-questions {
    &.player-answers {
        .main-content {
            padding-top: 50px;
            min-height: calc(100vh - 50px);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .pq-whole-section {
                width: 100%;
                padding: 5% 25%;
                @media screen and (max-width: $md-screen) and (min-width: $sm-screen) {
                    padding: 5% 10%;
                    padding-top: 14%;
                }
                @media screen and (max-width: $sm-screen) {
                    padding: 5% 10px;
                    padding-top: 20px;
                }
                @media screen and (max-width: $xs-screen) {
                    padding: 5% 0px;
                }
                .answer-option-section {
                    // background: rgba(14, 18, 49, 0.4);
                    border-radius: 24px;
                    padding: 20px;
                    @media screen and (min-width: $sm-screen) {
                        margin-top: 20px !important;
                        max-height: initial !important;
                    }
                    @media screen and (max-width: $sm-screen) {
                        padding-top: 20px;
                        margin-top: 50px;
                    }
                    @media screen and (max-width: $xs-screen) {
                        padding-top: 10px;
                        margin-top: 75px;
                        border-radius: 0;
                    }
                    .select-status {
                        box-shadow: 2px 2px 0px #18C27F, inset 0px 0px 0px 1px #D8CCC1;
                        border-radius: 12px;
                        height: 10px;
                    }
                    .first-answer {
                        .select-status {
                            width: 8%;
                            background: #4FBE3D;
                        }
                    }
                    .second-answer {
                        .select-status {
                            width: 56%;
                            background: #3D80BE;
                        }
                    }
                    .third-answer {
                        .select-status {
                            width: 12%;
                            background: #E95D6E;
                        }
                    }
                    .fourth-answer {
                        .select-status {
                            width: 24%;
                            background: #A8F55B;
                        }
                    }
                    .answer-options {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &.validate-answer {
                        .selected-answer {
                            &.wrong-a {
                                background-color: transparent;
                                .answer-option {
                                    box-shadow: 0px 0px 0px 2px #0e1231, 0px 0px 0px 10px #F56666;
                                    .option-detail {
                                        &.pa-option-detail {
                                            background-color: rgba(14, 18, 49, 0.4);
                                            color: $white;
                                            text-decoration: line-through;
                                        }
                                    }
                                }
                            }
                            &.right-a {
                                background-color: transparent;
                                .answer-option {
                                    box-shadow: 0px 0px 0px 2px #A8F55B, 0px 0px 0px 10px #66B866;
                                    .option-detail {
                                        &.pa-option-detail {
                                            background-color: rgba(14, 18, 49, 0.4);
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                        .wrong-a {
                            background-color: #F56666;
                            border-radius: 12px;
                            padding: 1px 10px 5px;
                            .answer-options {
                                text-decoration: line-through;
                            }
                        }
                        .right-a {
                            background-color: #66B866;
                            border-radius: 12px;
                            padding: 1px 10px 5px;
                        }
                    }
                }
            }
        }
    }
} 

.space-wrap {
    white-space: initial !important;
}