@import '../../index.scss';

.yakeety-footer {
    position: fixed;
    bottom: 0;
    background-color: $white;
    z-index: 9999 !important;
    .info-section {
        font-size: 14px;
        color: $btn-color;
        cursor: pointer;
    }
    .btn.btn-primary {
        font-size: 14px;
        background-color: transparent;
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: $btn-color;
        &:hover {
            color: $btn-color;
        }
        &:active:focus,
        &:active,
        &:focus {
            box-shadow: none;
            color: $btn-color;
            background-color: transparent;
            background: none;
        }
    }
    .btn.sidebar-btn {
        width: unset;
    }
}
.footer-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    .footer-overlay-img {
        bottom: 60px;
        
        @media screen and (max-width: $md-screen) {
            bottom: 40px;
            img {
                width: 100%;
            }
        }
        .ho-logo-section-footer-logo {
            top: 25px;
            left: -20px;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 130px;
            @media screen and (max-height: 750px) {
                width: 110px;
            }
            @media screen and (max-width: $sm-screen) {
                width: 70px;
                top: 15px;
                left: 0;
            }
            @media only screen and (max-device-height : 500px) and (max-device-width : 900px) and (orientation : landscape){
                top: 22px;
                width: 80px;
            }
        }
    }
}

.footer-overlay-img-player {
    position: fixed;
    right: 0;
    bottom: 0;
    .company-logo {
        width: 150px;
        bottom: 15px;
        right: 20px;
        height: 57px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
    }
    &.for-presentation {
        bottom: 55px;
    }
}

.full-screen-game {
    .footer-overlay-img-player {
        &.for-presentation {
            bottom: 0;
        }
    }
    .footer-bg .footer-overlay-img {
        bottom: 0;
    }
    .main-content {
        min-height: 100vh;
    }
}

.for-player-screen {
    .footer-bg .footer-overlay-img {
        bottom: 0;
    }
}


.footer-plb-2{
    .long-footer {
        @media screen and (max-width:$sm-screen) {
            max-width: 450px;
            width: 100%;
        }
        .defualt-right-cloud{
            display: block !important;
            img {
                @media screen and (max-width:$sm-screen) {
                    height: 60px;
                }
            }
        }
    }
    .footer-bg {
        .footer-overlay-img{
            .ho-logo-section-footer-logo {
                @media screen and (max-width:$sm-screen) {
                    width: 80px;
                    top: 20px;
                }
            }
        }
    }
}