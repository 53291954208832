@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

$sub-font: "RacingSans";

$team-color: var(--team-color);

// Responsive Screens
$xl-screen: 1399.98px;
$lg-screen: 1199.98px;
$md-screen: 991.98px;
$sm-screen: 767.98px;
$xs-screen: 575.98px;



// $assetPath :"path";

@function get($var) {
    @return map-get($custom-properties, $var);
  }
  $custom-properties: (
    'navbar-transparent': true,
  );
  
  @each $key, $value in $custom-properties {
    :root {
      --#{$key}: #{$value};
    }
  }

  .skin-car.main-bg.player-skin {
    background-image: url(../round/DsukRush/bg_no_car.jpg);
    &.background_v1_34C5A2{
        background-image: url(../player/background/background_v1_34C5A2.jpg) !important;
    }
    &.background_v2_34C5A2{
        background-image: url(../player/background/background_v2_34C5A2.jpg) !important;
    }
    &.background_v3_34C5A2{
        background-image: url(../player/background/background_v3_34C5A2.jpg) !important;
    }
    &.background_v1_85C534{
        background-image: url(../player/background/background_v1_85C534.jpg) !important;
    }
    &.background_v2_85C534{
        background-image: url(../player/background/background_v2_85C534.jpg) !important;
    }
    &.background_v3_85C534{
        background-image: url(../player/background/background_v3_85C534.jpg) !important;
    }
    &.background_v1_346EC5{
        background-image: url(../player/background/background_v1_346EC5.jpg) !important;
    }
    &.background_v2_346EC5{
        background-image: url(../player/background/background_v2_346EC5.jpg) !important;
    }
    &.background_v3_346EC5{
        background-image: url(../player/background/background_v3_346EC5.jpg) !important;
    }
    &.background_v1_6234C5{
        background-image: url(../player/background/background_v1_6234C5.jpg) !important;
    }
    &.background_v2_6234C5{
        background-image: url(../player/background/background_v2_6234C5.jpg) !important;
    }
    &.background_v3_6234C5{
        background-image: url(../player/background/background_v3_6234C5.jpg) !important;
    }
    &.background_v1_C59C34{
        background-image: url(../player/background/background_v1_C59C34.jpg) !important;
    }
    &.background_v2_C59C34{
        background-image: url(../player/background/background_v2_C59C34.jpg) !important;
    }
    &.background_v3_C59C34{
        background-image: url(../player/background/background_v3_C59C34.jpg) !important;
    }
    &.background_v1_C534A5{
        background-image: url(../player/background/background_v1_C534A5.jpg) !important;
    }
    &.background_v2_C534A5{
        background-image: url(../player/background/background_v2_C534A5.jpg) !important;
    }
    &.background_v3_C534A5{
        background-image: url(../player/background/background_v3_C534A5.jpg) !important;
    }
    &.background_v1_C5344E{
        background-image: url(../player/background/background_v1_C5344E.jpg) !important;
    }
    &.background_v2_C5344E{
        background-image: url(../player/background/background_v2_C5344E.jpg) !important;
    }
    &.background_v3_C5344E{
        background-image: url(../player/background/background_v3_C5344E.jpg) !important;
    }
    &.background_v1_C53434{
        background-image: url(../player/background/background_v1_C53434.jpg) !important;
    }
    &.background_v2_C53434{
        background-image: url(../player/background/background_v2_C53434.jpg) !important;
    }
    &.background_v3_C53434{
        background-image: url(../player/background/background_v3_C53434.jpg) !important;
    }

    &.background_v1_B84F3E{
        background-image: url(../player/background/background_v1_B84F3E.jpg) !important;
    }
    &.background_v2_B84F3E{
        background-image: url(../player/background/background_v2_B84F3E.jpg) !important;
    }
    &.background_v3_B84F3E{
        background-image: url(../player/background/background_v3_B84F3E.jpg) !important;
    }

    &.background_v1_B99149{
        background-image: url(../player/background/background_v1_B99149.jpg) !important;
    }
    &.background_v2_B99149{
        background-image: url(../player/background/background_v2_B99149.jpg) !important;
    }
    &.background_v3_B99149{
        background-image: url(../player/background/background_v3_B99149.jpg) !important;
    }

    &.background_v1_A4AD42{
        background-image: url(../player/background/background_v1_A4AD42.jpg) !important;
    }
    &.background_v2_A4AD42{
        background-image: url(../player/background/background_v2_A4AD42.jpg) !important;
    }
    &.background_v3_A4AD42{
        background-image: url(../player/background/background_v3_A4AD42.jpg) !important;
    }

    &.background_v1_3FC26F{
        background-image: url(../player/background/background_v1_3FC26F.jpg) !important;
    }
    &.background_v2_3FC26F{
        background-image: url(../player/background/background_v2_3FC26F.jpg) !important;
    }
    &.background_v3_3FC26F{
        background-image: url(../player/background/background_v3_3FC26F.jpg) !important;
    }

    &.background_v1_408BD1{
        background-image: url(../player/background/background_v1_408BD1.jpg) !important;
    }
    &.background_v2_408BD1{
        background-image: url(../player/background/background_v2_408BD1.jpg) !important;
    }
    &.background_v3_408BD1{
        background-image: url(../player/background/background_v3_408BD1.jpg) !important;
    }

    &.background_v1_5F4CA3{
        background-image: url(../player/background/background_v1_5F4CA3.jpg) !important;
    }
    &.background_v2_5F4CA3{
        background-image: url(../player/background/background_v2_5F4CA3.jpg) !important;
    }
    &.background_v3_5F4CA3{
        background-image: url(../player/background/background_v3_5F4CA3.jpg) !important;
    }

    &.background_v1_5F4CA3{
        background-image: url(../player/background/background_v1_5F4CA3.jpg) !important;
    }
    &.background_v2_5F4CA3{
        background-image: url(../player/background/background_v2_5F4CA3.jpg) !important;
    }
    &.background_v3_5F4CA3{
        background-image: url(../player/background/background_v3_5F4CA3.jpg) !important;
    }
    
    @media screen and (max-width:$md-screen) {
        background-size: cover;
        background-position-y: bottom;
    }

    // @media screen and (max-width:450px) {
    //     background-position-x: calc(100% + 360px);
    // }

    .user-registration{
        &.presentation-player-start {
            background-image: none;
            .signup {
                .signup-image {
                    background-image: url(../round/DsukRush/bg_car.jpg);
                    .presentation-content {
                        h4 {
                            font-family: "RacingSans";
                        }
                    }
                }
            }
            .signup {
                .signup-form {
                    .mob-drop{
                        .dropdown{
                            .dropdown-toggle{
                                &::after {
                                    @media screen and (max-width:$xs-screen) {
                                        margin-left: 5em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .signup{
            .signup-form {
                label {
                    font-family: Space Mono;
                    font-size: 14px;
                }
                .player-inv-head {
                    h5, h1, h3 {
                        font-family: "RacingSans", cursive;
                    }
                }
                .player-name{
                    font-family: "RacingSans", cursive;
                }
            }
        }
    }
    .player-inv-head {
        h5, h1, h3 {
            font-family: "RacingSans", cursive;
        }
    }
    button.btn {
        font-family: Space Mono;
        font-size: 14px;
        &.ans-send-btn{
            font-size: 24px;
            background: var(--button-color);
        }
    }
    .main-content {
        .it-heading{
            .title-sec{
                background-color: $team-color;
                display: inline-block;
                padding: 2px 15px;
                margin-bottom: .5rem;
                transform: skewX(-15deg);
                h1{
                    margin-bottom: 0;
                    transform: skewX(15deg);
                }
            }
        }
        .it-content{
            font-family: Space Mono;
            font-size: 25x;
            
            @media screen and (max-width:$sm-screen) {
                font-size: 22px;
            }
            @media screen and (max-width:$xs-screen) {
                font-size: 14px;
            }
        }
        .pq-head , .drush-head h2, .drush-round h2 {
            font-family: "RacingSans";
            font-size: 35px;
            display: inline-block;
            padding: 0px 15px;
            background-color: var(--team-color);
            transform: skewX(-15deg);
            span{
                transform: skewX(15deg);
                display: inline-block;
                word-break: break-word;
            }
            @media screen and (max-width:$sm-screen) {
                font-size: 1.5rem;
                margin-top: .5rem;
            }
        }
        .drush-head{
            h2{
                font-size: 22px;
                padding: 0px 10px;
            }
        }
        .podium-result {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            @media screen and (max-width:$md-screen) {
                display: block;
            }
            h2{
                background-color: $team-color;
                transform: skewX(-15deg);
                padding: 0px 15px;
                @media screen and (max-width:$md-screen) {
                    max-width: 50%;
                    margin: 0 auto;
                }
                span{
                    transform: skewX(15deg);
                    display: inline-block;
                }
            }
            .dusk-space-0{
                padding: 0 !important;
            }
        }
        .leader-board-section{
            .leader-board-table-row {
                position: relative;
                @media screen and (max-width:$sm-screen) {
                    transform: skewX(0deg) skewY(15deg) !important;
                    background: rgba(0,0,0,.3);
                    margin-right: 5px !important;
                }
                @media screen and (max-width:$xs-screen) {
                    padding-bottom: 0px;
                }
                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background: rgb(var(--element-color-rgb));
                    margin: initial;
                }
                .small-space{
                    padding: 0;
                }
                .list-item{
                    .tr-count {
                        margin-left: 7px;
                    }
                    .teb-icon{
                        transform: skewX(15deg);
                        padding-left: 5px;
                        svg{
                            margin-right: 0 !important;
                        }
                    }
                }
                p, .tr-count{
                    @media screen and (max-width:$sm-screen) {
                        transform: skewY(-15deg) skewX(0deg);
                    }
                }
                &.round-qstn {
                    transform: skewX(-15deg) skewY(0deg) !important;
                    p, .tr-count{
                        @media screen and (max-width:$md-screen) {
                            transform: skewY(0deg) skewX(15deg);
                        }
                    }
                }
            }

            ul.leaderboard-ul-list {
                .list-item {
                    padding-top: 1px;
                }
                .player-point-round {
                    span:last-child {
                        p {
                            padding-top: 3px;
                        }
                    }
                }
                &.qst-rnd-lb{
                    .list-item {
                        p{
                            @media screen and (max-width:$xs-screen) {
                                font-size: 11px;
                            }
                            @media screen and (max-width:350px) {
                                font-size: 9px;
                            }
                        }
                    }
                }
            }
        }
        .custom-bg-forplayer-table{
            .leader-board-section {
                background: transparent;
            }
        }
        .round-logo {
            width: 220px;
            height: auto;
            position: relative;
            .round-won{
                &.round-planet {
                    .round-won-svg {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .drush-round{
            justify-content: space-between !important;
            margin-bottom: 3em;
            @media screen and (max-width:$sm-screen) {
                flex-direction: column-reverse;
            }
        }
        &.dusk-skin {
            justify-content: center !important;
        }
        .podium-result{
            .podium-rating-section {
                background: transparent !important;
                display: flex;
                width: 320px;
                align-items: flex-end;
                padding-bottom: 20px;
                @media screen and (max-width:$sm-screen) {
                    align-items: center;
                    flex-direction: column-reverse;
                }
                .star-rating {
                    left: 0px;
                    position: relative !important;
                }
                .won-text {
                    text-align: right;
                    margin-right: 20px;
                    @media screen and (max-width:$sm-screen) {
                        text-align: center;
                    }
                }
                .small-star,.big-star{
                    display: none;
                }
                .team-pos{
                    display: block;
                    img {
                        width: 150px;
                        @media screen and (max-width:$sm-screen) {
                            width: 130px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:$sm-screen) {
            justify-content: center !important;
        }

        .lite-bg {
            background: rgba(0, 0, 0, 0.2);
            padding-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(4px);
            .astro-head h2 {
                background-color: var(--team-color);
                transform: skewX(-15deg);
                padding: 2px 10px;
                span{
                    transform: skewX(15deg);
                    display: inline-block;
                }
                @media screen and (max-width:$sm-screen) {
                    font-size: 1.25rem;
                }
            }
        }
    }

    
    .dusk-player-lb{
        .tr-count{
            margin-top: 5px;
        }
        .team-icon {
            padding: 5px 0;
            width: 100%;
            margin-bottom: 5px;
            background: rgb(var(--element-color-rgb));
            min-height: 2rem;
            svg{
                transform: skewY(-15deg) skewX(0deg);
                font-size: 18px;
            }
        }
        p{
            color: rgb(var(--element-color-rgb)) !important;
            padding: 2px 4px;
        }
        .mob-full-width, .total-table{
            background: rgb(var(--element-color-rgb));
            margin-bottom: 0 !important;
            position: relative;
            bottom: -2px;
            padding: 3px 2px;
            font-family: "Fredoka One", cursive;
            @media screen and (max-width:$xs-screen) {
                padding: 2px 2px 2px 0;
                
            }
            p{
                color: #211D19 !important;
            }
        }
    }

    .player-questions {
        .main-content {
            @media screen and (max-width:$xs-screen) {
                align-items: flex-start;
                padding-top: 80px;
            }
            .pq-whole-section {
                padding-top: 2%;
                padding-bottom: 2%;
                background: rgba(0, 0, 0, 0.35);
                backdrop-filter: blur(4px);
                @media screen and (max-width:$xl-screen) {
                    padding: 2% 15%;
                }
                @media screen and (max-width:$sm-screen) {
                    padding: 4%;
                }
                @media screen and (max-width:$xs-screen) {
                    padding: 0%;
                    background: transparent;
                    backdrop-filter: blur(0px);
                }
                &.no-bg-shade {
                    padding-top: 2%;
                    padding-bottom: 2%;
                    background: transparent !important;
                    @media screen and (max-width:$xl-screen) {
                        padding: 2% 15%;
                    }
                    @media screen and (max-width:$sm-screen) {
                        padding: 4%;
                    }
                }
                .pq-head{
                    width: auto;
                    font-size: 35px;
                    background-color:var(--team-color);
                    @media screen and (max-width:$sm-screen) {
                        width:90%;
                        font-size: 25px;
                    }
                    @media screen and (max-width:$xs-screen) {
                        font-size: 23px;
                        transform: skewX(-10deg);
                        span{
                            transform: skewX(10deg);
                        }
                    }
                }
                .answer-option-section{
                    @media screen and (max-width:$xs-screen) {
                        background: rgba(0, 0, 0, 0.35);
                        backdrop-filter: blur(4px);
                        padding: 20px !important;
                    }
                    h6{
                        font-size: 20px;
                    }
                    .option-count {
                        font-family: 'RacingSans';
                        font-size: 42px;
                        // font-family: 'Qualy';
                    }
                    .correct-ans{
                        font-size: 1.125rem;
                    }
                    
                    .whole-teams-answer{
                        .answer-status-percentage{
                            font-size: 24px;
                            font-family: 'Space Mono';
                            @media screen and (max-width:$xs-screen) {
                                font-size: 20px;
                            }
                        }
                    }
                }
                .answer-options{
                    font-family: 'Space Mono';
                }
                
            }
        }
        .header-bg {
            @media screen and (max-width:$sm-screen) {
                background-color: transparent;
            }
        }
        // .team-info-footer {
        //     @media screen and (max-width:$sm-screen) {
        //         top: initial;
        //         bottom: 20px;
        //     }
        // }
        .header-bg {
            .ho-logo-section-team-logo {
                @media screen and (max-width:$sm-screen) {
                    display: block;
                }
            }
        }
    }
    .drush-head{
        @media screen and (max-width:$md-screen) {
            display: none !important;
        }
    }
    .astro-head{
        @media screen and (max-width:$md-screen) {
            display: flex !important;
        }
    }

    
    .button-sec{
        position: relative;
        z-index: 99;
        @media screen and (max-width:$xs-screen) {
            margin-bottom: 20px;
        }
    }
    
    .player-questions{
        .team-info-footer{
            h6{
                font-family: "RacingSans";
            }
        }
    }

    .team-info-footer{
        .team-icon-sec{
            border: none;
        }
    }

    .alert {
        font-family: Comfortaa;
    }
    .btn-secondary.disabled, .btn-secondary:disabled {
        background-color: #858C97;
        border-color: #858C97;
        color: #858C97 !important;
        font-family: 'Space Mono';
    }
    .font-racing{
        font-family: "RacingSans" !important;
    }

    .header-bg {
        .header-overlay-img {
            @media screen and (max-width:$xs-screen) {
                margin-top: 0;
            }
            .ho-logo-section-header-logo{
                @media screen and (max-width:$xs-screen) {
                    top: 10px;
                }
            }
            .ho-logo-section-team-logo{
                @media screen and (max-width:$xs-screen) {
                    top: 10px;
                    width: 70px;
                }
            }
            .normal-header{
                .defualt-left-cloud,.defualt-right-cloud{
                    @media screen and (max-width:$sm-screen) {
                        width: 30%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .presentation-start-view{
        .footer-section{
            .footer-bg{
                height: 50px;
                @media screen and (max-width:$sm-screen) {
                    width: 100%;
                }
                .ho-logo-section-footer-logo{
                    // opacity: 0;
                    @media screen and (max-width:$xs-screen) {
                        width: 200px;
                        top: initial;
                        bottom: 30px;
                    }
                }
            }
        }
        &.only-Lboard{
            .footer-section{
                .footer-bg{
                    .ho-logo-section-footer-logo{
                        opacity: 1;
                        width: 140px;
                        top: 0;
                    }
                }
            }
        }
    }

}


.modal.car {
    background: linear-gradient(225deg, $team-color 0%, rgba(0,0,0,.5) 100%) !important;
    z-index: 99999 !important;

    h1,h2,h3,h4,h5,h6 {
        font-family: $sub-font;
    }

    .modal-header h5, .modal-header h3 {
        font-family: "RacingSans" !important;
    }
    li{
        font-family: "Space Mono" !important;
    }
}

